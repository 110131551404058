import { useRef, useState, useEffect, useMemo } from "react"
import {  useThree, useFrame } from "@react-three/fiber"
import { useGLTF } from "@react-three/drei"

//The component used to render both shirts and helmets.
function Scene(props) {
	console.log("the changed key texture scene check", props.textures);
	console.log("Scene check wsbdnr", props);
	const ref = useRef();
	
	//console.log("GLTF stuff:",props.models.location)
	//'props.model' is a string identifying the kind of model. This should be idential to the filename, as seen below, and is used throughout.
	const { nodes } = useGLTF(`./models/${props.models.location}.glb`);
	//Mesh_colours is similar to the above but uses colour inputs.
	let mesh_colours = props.textures;
	//console.log("wsbdnr props", mesh_colours)
	//console.log(mesh_colours);
	//An array of JSX elements that creates the model from meshes and so on.
	let return_object = [];
	
	let position = [0,0,0];
	if(props.models.position){
		position = props.models.position;
	}
	let rotation = [0,0,0];
	if(props.models.rotation){
		rotation = props.models.rotation;
	}
	let scale = [1,1,1];
	if(props.models.scale){
		//console.log("Scaling.");
		scale = props.models.scale;
	}

	//console.log(props.models);

	//Scale model as needed, runs when the JSON changes // Removed for now, what benefits does this have over just 
	/*useEffect(() => {
		if (props.json.scale && typeof ref.current.children !== 'undefined') {
			console.log("Scaling in uf?");
			// eslint-disable-next-line
			for (const [key, mesh] of Object.entries(ref.current.children)) {
				mesh.scale.set(scale[0], scale[1], scale[2]);
			}
		}
	}, [props.json])*/

	////console.log("wsbdnr meshes", props.models.meshes)
	//Creates the model, depending on which option is selected.
	for (const [key, mesh] of Object.entries(props.models.meshes)){
		//console.log("wsbdnr mesh", key, mesh);
		if (mesh.is_mesh === false) {
			continue;
		}
		
		let selected_material;
		////console.log("wsbdnr keys", key)
		if (mesh.uses_svg === true) {
			selected_material = mesh_colours["svg"];
			//console.log("wsbdnr conditional uses_svg?", selected_material, mesh_colours["svg"])
		} else if  ( mesh.texture ) {
			selected_material = mesh_colours[key];                                                                
		} else {
			selected_material = mesh_colours[key];
		}

		//If the mesh is an SVG and has two sides or has two materials to use
		if (mesh.uses_svg && (mesh.twoSided && selected_material.length === 2)) {
			//console.log("wsbdnr conditional if", selected_material, mesh_colours)
			return_object.push(
				<>
					<mesh key={"exterior_"+key} material={selected_material["exterior"]} geometry={nodes[key].geometry} position={position} rotation={rotation} scale={scale}></mesh>
					<mesh key={"interior_"+key} material={selected_material["interior"]} geometry={nodes[key].geometry} position={position} rotation={rotation} scale={scale}></mesh>
				</>
			)
		} else {
			//console.log("wsbdnr conditional else", selected_material, mesh_colours, key, mesh)

			if (mesh.uses_svg) {
				selected_material = selected_material["exterior"];
				//console.log("wsbdnr conditional using SVG in if", key, selected_material, mesh_colours)

			}
			
			//console.log("wsbdnr conditional using SVG after if", selected_material, mesh_colours)
			return_object.push(
				<mesh key={key} material={selected_material} geometry={nodes[key].geometry} position={position} rotation={rotation} scale={scale}></mesh>
			)
		}
	}

	//console.log("wsbdnr ret_obj", return_object);

	//Returns the model inside a group. The 'onPointer' stuff creates the hover effect from above, I think.
	return (
		<group key={"group"} ref={ref} >
			{return_object}
		</group>
	);
}

export default Scene;