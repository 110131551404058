import React from 'react';
import ReactDOM from 'react-dom/client';
//import './index.css';
import './local.css';
//import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

let css_link = document.createElement('link');
let page_url = new URL(window.location.href);
//console.log(page_url.hostname);
css_link.rel = "stylesheet";
css_link.href = `https://us-central1-online-kit-designer.cloudfunctions.net/serveCSS/${page_url.hostname}`;
document.head.appendChild(css_link)

// Step 1: Create and insert the loading div
const loadingDiv = document.createElement('div');
loadingDiv.innerHTML = `
	<div class="okd-loading">
		<div class="okdLoader">
			<div class="canvas canvas6">
					<div class="spinner6 p1"></div>
					<div class="spinner6 p2"></div>
					<div class="spinner6 p3"></div>
					<div class="spinner6 p4"></div>
			</div>
			<div class="loadingText">LOADING</div>
		</div>
	</div>
	<style>
			body {
				margin: 0;
				font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
		}

		.okd-loading {
				transition: opacity 0.5s ease-out; /* Adjust time as needed */
				opacity: 1; /* Fully visible initially */
		}
		body.okd-start-transition .okd-loading {
				opacity: 0;
				pointer-events: none; /* Prevents clicks/touches while fading out */
				/* use a delay equal to the transition to set display none after the fade out is done */
				animation: hide 0.5s forwards; /* Adjust time as needed */
				animation-delay: 0.5s; /* Adjust time as needed */
		}

		@keyframes hide {
				to {
						display: none;
				}
		}
				.okd-loading {
						position:fixed;top:0;left:0;bottom:0;right:0;background-color:#ACACAC;z-index:20000;
				}
				.fs {
			opacity: 0;
		}
		.okdLoader {
			justify-content: space-around;
			margin: 0 auto;
			width: 200px;
			position: absolute;
			left: 0;
			right: 0;
			text-align: center;
			top: 50%;
			transform: translateY(-50%);
			z-index: 1000;
			/* Spinner 6 ends here */
		}
		.okdLoader .canvas {
			align-items: center;
			display: block;
			justify-content: center;
			margin: 1em auto;
		}
		.okdLoader .loadingText {
			color: #fff;
			display: block;
		}
		.okdLoader .spinner6 {
			background: #fff;
			border-radius: 50%;
			height: 1em;
			margin: .1em;
			width: 1em;
			display: inline-block;
		}
		.okdLoader .p1 {
			animation: fall 1s linear .3s infinite;
		}
		.okdLoader .p2 {
			animation: fall 1s linear .2s infinite;
		}
		.okdLoader .p3 {
			animation: fall 1s linear .1s infinite;
		}
		.okdLoader .p4 {
			animation: fall 1s linear infinite;
		}
		@keyframes fall {
			0% {
				transform: translateY(-15px);
			}
			25%,
			75% {
				transform: translateY(0);
			}
			100% {
				transform: translateY(-15px);
			}
		}
		@media (max-width: 600px) {
			.okdLoader .container {
				align-items: center;
				flex-direction: column;
			}
			.okdLoader .canvas {
				margin: 1em;
			}
		}
	</style>
`;
document.body.appendChild(loadingDiv);


const root = ReactDOM.createRoot(document.getElementById('okd-Designer'));
// Function to render the React app
const renderReactApp = () => {
	root.render(
		<React.StrictMode>
			<App />
		</React.StrictMode>
	);
};

// Function to check if HTML is ready
const checkHtmlReady = () => {
	//console.log("Checking if HTML ready");
	if (document.getElementById('okd-ready') || window.location.hostname === "localhost") {
		renderReactApp();
	} else {
		// Check again after a delay
		setTimeout(checkHtmlReady, 100);
	}
};

css_link.onload = function () {
	// Start checking for HTML readiness
	checkHtmlReady();
}

reportWebVitals();
