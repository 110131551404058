// Version 0.3.0
import "./App.css";
import { Children, useRef, useState, useEffect } from "react"
import { TextureLoader, MeshPhysicalMaterial, MeshPhongMaterial, SRGBColorSpace, BackSide, FrontSide, Vector3} from "three"
import { Canvas, useThree, useFrame, extend } from "@react-three/fiber"
import { ContactShadows, Environment, OrbitControls } from "@react-three/drei"
import { useSnapshot } from "valtio"
//Firebase stuff
import { initializeApp } from 'firebase/app';
import { getStorage, ref,/* listAll,*/ getDownloadURL, uploadBytes } from 'firebase/storage';

//Other components
import state from './components/state'; // Import the state variable
import Picker from './components/picker'
import RotationButton from "./components/rotationbutton";
import NewSaveButton from './components/NewSaveButton.js';
import LogoInput from './components/logoinput.js'
import Scene from './components/scene';


import { fetchDoc, dbx, getDoc, doc } from './firebase-config.js';
import { applyColours, applyTreatment, assembleDesign, fetchOverlay, fetchSVGs } from "./components/ModelTextureLoader.jsx";
import { overlay } from "three/examples/jsm/nodes/Nodes.js";

const customer_colours = {"C0":"#5F6B71","C1":"#51046B","C2":"#5CBF1A","C3":"#CD1929","C4":"#133782", "C5":"#1B9958","C6":"#CD1929","C7":"#C5CAC5","C8":"#ABAEAF","C9":"#ABAEAF","C10":"#1A8980"}
// Your web app's Firebase configuration
const firebaseConfig = {
	apiKey: "AIzaSyDB2jrPHH6eBO_bEnxVOY6JADbkw1_u3vs",
	authDomain: "online-kit-designer.firebaseapp.com",
	projectId: "online-kit-designer",
	storageBucket: "online-kit-designer.appspot.com",
	messagingSenderId: "613566310845",
	appId: "1:613566310845:web:e083849858a38549e44ad4"
};

// Extend the OrbitControls to be used in react-three-fiber
extend({ OrbitControls });

//Relocate?
const app = initializeApp(firebaseConfig);
//const db = getFirestore(app);
const storage = getStorage(app);

state.colours = customer_colours;

function CameraControls(props) {
	const [rotation, setRotation] = useState(false);
	const { camera, gl } = useThree();
	const controls = useRef(null);
	const zeroRef = useRef(new Vector3()); // Holds the target position
	const targetRef = useRef(new Vector3()); // Holds the target position
	const focusRef = useRef(new Vector3()); // Holds the focus position
	const [gotofocus, setGotofocus] = useState(false);  

	useEffect(() => {
		//if(document.getElementById("camera-notes")){
		//    document.getElementById("camera-notes").innerHTML = `props.snapAngle changed ${props.snapAngle}`;
		//}
		if (
				props.snapAngle && 
				props.snapAngle.x !== undefined && 
				props.snapAngle.y !== undefined && 
				props.snapAngle.z !== undefined
		) {
				// Update the target position
				console.log("kkjdf, rotation, is the CameraControls UF running?");
				targetRef.current.set(props.snapAngle.x, props.snapAngle.y, props.snapAngle.z);
				props.setRotate("stop"); // Assuming this stops other rotations/animations
		}
	}, [props.snapAngle]); // Only re-run if snapAngle changes
		
	useEffect(() => {
		if(document.getElementById("camera-notes")){
				document.getElementById("camera-notes").innerHTML = `props.snapFocus changed ${props.snapFocus}`;
		}

		if (
				props.snapFocus && 
				props.snapFocus.x !== undefined &&
				props.snapFocus.y !== undefined &&
				props.snapFocus.z !== undefined
		) {
				if(controls.current) {
						focusRef.current.set(props.snapFocus.x, props.snapFocus.y, props.snapFocus.z);
				}
		}
	}, [props.snapFocus]); // Only re-run if snapAngle changes

	useEffect(() => {
		console.log("kkjdf, rotation in CameraControls", props.rotate);
		console.log("Rotation troubleshooting, UF", props.rotate["haltRotation"], props.rotate);
		if (props.rotate === "play") {
			setRotation(true);
		} else {
			setRotation(false);
		}
	}, [props.rotate]);

	useFrame((state, delta) => {

		// THE CAMERA POSITION
		if (props.snapAngle && !targetRef.current.equals(camera.position)) {
			let distance = camera.position.distanceTo(targetRef.current);

			if(document.getElementById("camera-speed")){
				document.getElementById("camera-speed").innerHTML = `distance ${distance}`;
			}
			// Check if the camera is close enough to the target position
			
			if (distance > 30) {
				// If the camera is not close enough, continue interpolating towards the target
				camera.position.lerp(targetRef.current, delta * 1.5); // Adjust the 1 to control speed
			} else {
				// If the camera is close enough, snap it to the target and stop moving
				//camera.position.copy(targetRef.current);
				//camera.position.lerp(targetRef.current, 1); // Adjust the 1 to control speed

				props.setSnapAngle(false);
				setGotofocus(true);
			}

			// Update the camera matrices
			camera.updateProjectionMatrix();
		}
		
		// THE TARGET POINT
		//gotofocus && 
		/*if (props.snapFocus && focusRef.current && controls.current && !focusRef.current.equals(controls.current.target)) {
				
			let distance_to_zero = controls.current.target.distanceTo(zeroRef);
			let distance_to_target = controls.current.target.distanceTo(focusRef.current);
			if(document.getElementById("camera-notes")){
					document.getElementById("camera-notes").innerHTML = `distance ${distance_to_target}`;
			}

			// Check if the camera's focus is close enough to the target position
			const target_threshold = 0.1; // Define a small threshold distance

			if (distance_to_target > target_threshold) {
				// If the camera's focus is not close enough, continue interpolating towards the target
				controls.current.target.lerp(focusRef.current, delta * 1.5); // Adjust the speed factor as needed
				controls.current.update(); // Necessary to let OrbitControls know the target has changed
			} else {
				// If the camera's focus is close enough, snap it to the target and stop moving
				controls.current.target.copy(focusRef.current);
				if(distance_to_zero < 1){
					props.setSnapFocus(false);
				}
				setGotofocus(false);
			}
		}*/
			
		if(document.getElementById("camera-coords")){
			document.getElementById("camera-coords").innerHTML = `x:${camera.position.x} <br>y:${camera.position.y} <br>z:${camera.position.z} <br>tx:${controls.current.target.x} <br>ty:${controls.current.target.y} <br>tz:${controls.current.target.z}`;
		}
	});

	return (
		<OrbitControls
				//target={[.6, .4, 0]}

				ref={controls}
				args={[camera, gl.domElement]}
		
				enableZoom={true}
				enablePan={false}
		
				autoRotate={rotation}
				autoRotateSpeed={3}
				
				//minPolarAngle={Math.PI / 4}
				//maxPolarAngle={Math.PI / 1.5}
				
				minDistance={500}
				maxDistance={800}
				dampingFactor={0.5}
		/>
	);
}

function stringToHash(string) {
	if (typeof string === "object") {
		string = JSON.stringify({...string});
	} else if (typeof string === "array") {
		string = string.toString();
	}
    return string.split('').reduce((hash, char) => {
        return char.charCodeAt(0) + (hash << 6) + (hash << 16) - hash;
    }, 0);
}

let loadCounter = 0;

function incrementClass() {
	// Increment the counter
	loadCounter++;

	// Remove any existing class that matches 'okd-loaded-*'
	const classList = document.body.className.split(/\s+/); // Split classes into an array
	const existingClass = classList.find(className => className.startsWith('okd-loaded-'));
	if (existingClass) {
		document.body.classList.remove(existingClass);
	}

	// Add the new class with the incremented counter
	document.body.classList.add(`okd-loaded-${loadCounter}`);
		
	// Specifically, when the counter reaches 10, add 'okd-start-transition' class
	if (loadCounter > 3) {
		document.body.classList.add('okd-start-transition');
	}
}

function changeHash(sUH,prefix) {
    //currentHash = window.location.hash.replace("#","");
	console.log("hi-895 above functc",currentHash);
	let updatedHash = currentHash;
    if (currentHash.startsWith("OKD")) {
        updatedHash = updatedHash.substring(3);
	}
	if (currentHash.startsWith("EDI")) {
        updatedHash = updatedHash.substring(3);
	}
	updatedHash = `${prefix}${updatedHash}`;
		sUH(updatedHash)
		window.history.pushState(null, null, `#${updatedHash}`);
    	const pushStateEvent = new Event("pushStateEvent");
    	window.dispatchEvent(pushStateEvent);
    //}else{
	//	alert("You've reached a dead end!");
	//}
}

function sanitizeAndValidateHash(hash) {
    hash = hash.replace(/^#/, '');
    var alphanumericRegex = /^[a-zA-Z0-9]+$/;
    var okdOrEdiRegex = /^(OKD|EDI)/;
	var okd_hash = hash.replace("OKD","").replace("EDI","")
    if (okdOrEdiRegex.test(hash) && alphanumericRegex.test(hash)) {
		if(okd_hash.length === 20){
			return hash;
		}
        return null;
    }
    return null;
}

let model_textures = {"colors":[],"pickers":[],"designs":[], "logo-text-pickers":[]};
 
let currentHash = '';

function returnIndex(list, target_value, key="id") {
	console.log("Crucially, in returnIndex", list, target_value, key)
	return list.findIndex(item => item[key] === target_value );
}

//The core function, aptly placed at the bottom.
function Functionality(props) {
	//const json = props.config;
	const blissTexture = props.brand;

	//UseRef: a value that needs to persist across renders but does not affect the component’s UI or trigger re-renders, or manipulating the DOM
	//UseState: a way to store and update values that affect the component's UI and trigger re-renders;

	//THIS CONTROLS THE ROTATIONS UNIVERSALLY
	const [rotateHook, setRotateHook] = useState("stop"); //Used to control rotation, therefore needed as state
	//const [rotateModel, setRotateModel] = useState(false);  
	//const [haltRotation, setHaltRotation] = useState(false);
	//const [rotationBrake, setRotationBrake] = useState(0.1);

	const initial_design = props.initialDesign;

	//const [user_input] = useState({})
	//const [theDesign, setTheDesign] = useState(initial_design.design); //Changes the model texture, needs re-render when it changes
	const [userDesign, setUserDesign] = useState({...initial_design, prev_design:null}); //Has most of the rest of the stuff for the texture, so needs re-render on change
	const [userHash, setUserHash] = useState(initial_design.okd_hash);
	const [view, setView] = useState(initial_design.view);

	//To replace model_texures
	const [buildObject, setBuildObject] = useState({
        "textures":{
            svg:{
				...props.ud_found.textures.svg,
				"exterior":""
			},
			...props.ud_found.textures
        },
        "colour_pickers":{
            "model_colour_pickers": {
                ...props.ud_found.pickers
            },
            "texture_colour_pickers": {}
        },
        "logo_text_pickers":{
            "logo_pickers": {},
            "text_pickers": {}
        },
        "cached_svgs": {
            "design": '',
            "overlay":''
        }
    })

	currentHash = (view == "edit" ? "EDI"+initial_design.okd_hash : "OKD"+initial_design.okd_hash);

	let caches = useRef({...props.incoming, "overlays":{}});

	//state variable hooked into the display of the save button. Below, the variable for the save button.
	const [previewSrc, setPreviewSrc] = useState(""); //Seems like we could use the useRef manipulation... stuff, to dictate the src instead of this. Depends if that's beyond the meaning of 'manipulation'

	const [snapAngle, setSnapAngle] = useState(false);
	const [snapFocus, setSnapFocus] = useState(false);
	
	const [pickerHooks, setPickerHooks] = useState({'designs':[],'colours':[],'text':[],'logos':[]}); //Control render things, should be state

	//The container of images
	const [uploadedImages, setUploadedImages] = useState([]);  //Needed for two components, but could be a ref.
    const [userEmail, setUserEmail] = useState(false); //Could turn this into a ref

	
	const [modalWindow, setModalWindow] = useState(false) //Used to render things, so best left as a state

	const canvasRef = useRef();
	
	//Possible refs
	const [savedMessage, setSavedMessage] = useState(""); //Not actually being used right now but I think it'll possibly be needed for later
	//const [showSave, setShowSave] = useState(true); //Governs the rendering of the save button. Could perhaps be a ref with some DOM manipulation, depends if the change from button to no button hinges on this
	//const [previewReady, setPreviewReady] = useState(false); //Maybe ref; for rotation, not used at present
	
	const [selectedLogoPicker, setSelectedLogoPicker] = useState(""); //Could be a ref, but dictates where the logos go, and so the attempt to turn it into a ref led to all manner of trouble with the order of play.
	//Update flags, seem to work as refs
	let updateLogos = useRef(true);
	let updateText = useRef(true);
	let tempCode = useRef("");
	let showSave = useRef(true);
	let changedColour = useRef("start");

	/*useEffect(() => {
		const handleHashChange = () => {
			if (window.location.hash && window.location.hash.length > 0) {
				setView("share");
				console.log("changeView to share");
			} else {
				console.log("changeView " + window.location.hash);
			}
		};
		// Add event listener for hash change
		window.addEventListener("popstate", handleHashChange);
		console.log("changeView xxx " + window.location.hash);
		console.log("popp",window.popstate);

		// Cleanup function to remove event listener
		//return () => {
		//	window.removeEventListener("hashchange", handleHashChange);
		//};
	}, []);*/

	let save_button;
	console.log("Rotation troubleshooting, rebuild of Functionality", rotateHook);

	console.log("InitialDesign99\n", JSON.stringify(userDesign.logos));
	console.log("propscon:", /*props, */buildObject/*, caches.current*/);
	console.log("tny-220, toppen:", userDesign.colours);
	//console.log("Compare textures:", model_textures.colors, buildObject.textures);
	//Set model textures and picker settings and so forth
	//ModelTextureLoader(model_textures, userDesign.design, props.config, userDesign, props.models.overlay, props.incoming.designs);
	//console.log("getting design results", model_textures);
	console.log("FTJ-88 props", props.incoming.designs);

	function handleOpening(eprops,e, picker="default") {
		let collapsePanel = e.target.parentElement;
		//console.log(collapsePanel)

		//// COLLAPSE INNER PANELS
		let allActiveElements = document.querySelectorAll('.okd-active-area .okd-active-area');
		for (let activeElement of allActiveElements) {
			activeElement.classList.remove('okd-active-area');
		}
		// Resets focus
		if(eprops.snapFocus !== false){
			eprops.setSnapFocus({"x":0,"y":0,"z":0})
		}

		//If the parent element is open, then close it. OR vice versa.
		if (collapsePanel.classList.value.includes("okd-active-area")) {
			collapsePanel.classList.remove("okd-active-area");
		}else{
			let other_collapsibles = document.getElementsByClassName("collapsible-container")
			for (let index = 0; index < other_collapsibles.length; index++) {
				other_collapsibles[index].classList.remove("okd-active-area");
			}
			collapsePanel.classList.add("okd-active-area");
		}


		let active_pickers = document.querySelectorAll('.selected-picker-logo');
		for (let active_picker of active_pickers) {
			active_picker.classList.remove('selected-picker-logo')
		}
		let disabled_pickers = document.querySelectorAll('.hidden-accordion')
		for (let disabled_picker of disabled_pickers) {
			disabled_picker.classList.remove('hidden-accordion')
		}

		if (picker !== "default") {
			let incrementor;
			let picker_hook_proxy = {...pickerHooks};
			switch (picker) {
				case 'designs':
					//Create design_picker if designs are incoming and they're not already there
					if (!isObjectEmpty(props.incoming.designs) && picker_hook_proxy[picker].length < 1) {
						for (const [key, value] of Object.entries(props.incoming.designs)) {
							let test_var = ""
							if (userDesign.design === value.id) {
								test_var = " current-logo-selection";
							}
							picker_hook_proxy[picker].push(<div data-design-name={value.name} className={value.id+" texture-selector-box"+test_var} style={{backgroundImage:("url("+value.previewData+")"), color:"red"}} key={key} id={key} onClick={(event) => {changeDesign(event, value.id)}}></div> )
							//picker_hook_proxy[picker].push(<img data-design-name={value.name} className={value.id+" texture-selector-box"+test_var} src={value.previewData} style={{color:"red"}} key={key} id={key} onClick={(event) => {changeDesign(event, value.id)}}></img> )
						}
					}
					//console.log('Switch: designs');
					break;
				case 'colours':
					//let merged_colours = {...buildObject.colour_pickers.texture_colour_pickers, ...buildObject.colour_pickers.model_colour_pickers};
					//If there are no pickers, or if there is apparently a design change.
					if (picker_hook_proxy[picker].length < 1 || userDesign.design !== userDesign.prev_design) {
						picker_hook_proxy[picker] = [];
						//Create pickers
						for (const [p_key, p_value] of Object.entries(buildObject.colour_pickers)) {
							for (const [key, value] of Object.entries(p_value)) {
								//If the 'key' pointing to the model is something that should be customisable, create the picker. Needs expanding to include SVG colours and the T-shirt re-colourables
								//if (userDesign.colours[key] !== undefined) {
									let relevant_mesh = Object.values(props.config.meshes).find((element) => element.colour_id === key);
									//console.log("Snap?", relevant_mesh)
									picker_hook_proxy[picker].push(
										<>
											<Picker
												title={value.name}
												palette_id={"default" /* FIX */}
												palettes={props.incoming.palette}
												id={key} key={key}
												model={props.incoming.model}
												mesh={relevant_mesh}
												rotate={rotateHook}
												userDesign={userDesign}
												setUserDesign={setUserDesign}
												setRotate={setRotateHook}
												changedColour={changedColour}
																			
												//setRotateToPoint={setRotateToPoint}
												//rotationBrake={rotationBrake}
												//setRotationBrake={setRotationBrake}
		
												setSnapAngle={setSnapAngle}
												setSnapFocus={setSnapFocus}
											>
												{/*<Palette/>*/}
											</Picker>
										</>
									);
								//}
							}
						}
					}
					//console.log('Switch: colours');
					break;
				case 'text':
					incrementor = 0;
					if (updateText.current) {
						console.log("FTJ-88, look at passed text?", buildObject["logo_text_pickers"]);
						picker_hook_proxy[picker] = [];
						for (const [key, value] of Object.entries(userDesign["text"]).sort()) {
							if (buildObject["logo_text_pickers"]["text_pickers"].includes(key)) {
								++incrementor
								picker_hook_proxy[picker].push(
									<>
										<div className="logo-upload-container">
											{"TEXT "+incrementor}
											<div className={"okd-logo-input-text"} id={key} style={{display:"flex"}}>
												<input placeholder="Enter text" name={"okd-logo-input-text"} type="text" defaultValue={value.string} />
											</div>
										</div>
									</>
								)
							}
						}
						updateText.current = false;
					}
					break;
				case 'logos':
					incrementor = 0;
					//console.log("FTJ-88, look at pickers", userDesign.design, userDesign.design, "||", picker_hook_proxy[picker].length, Object.values(userDesign["logos"]).length)
					//console.log("FTJ-88, look at pass conditions", userDesign.design !== userDesign.design, "||", picker_hook_proxy[picker].length > Object.values(userDesign["logos"]).length)
					//Only (re)generate the pickers if the design does not match the original or if there are less pickers than those prescribed in the design.

					if (updateLogos.current) {
						console.log("FTJ-88, look at passed logos?", buildObject);
						picker_hook_proxy[picker] = [];
						for (const [key, value] of Object.entries(userDesign["logos"]).sort()) {
							if (buildObject["logo_text_pickers"]["logo_pickers"].includes(key)) {
								++incrementor
								picker_hook_proxy[picker].push(
									<>
										{ <div className="design-accordion-panel">
											<div>
												<div className="colour-picker-container">
													<span style={{display:"flex"}}>
														<strong>{"LOGO "+incrementor}</strong>{/*<br/>
														<i>{value.name}</i>*/}
													</span>
													<img className="logo-picker-input" src={value.string} name={key} onClick={(e) => {togglePicker(key, e)}}></img>
												</div>
											</div>
										</div>}
									</>
								)
							}
							console.log("pickerhooksarray",picker_hook_proxy[picker], userDesign["logos"]);
						}
						updateLogos.current = false;
					}
					break;
			}

			//If rotation is paused, and an opening event is ongoing, then continue
			if (rotateHook === "pause") {
				console.log("kkjdf, rotation, is this bit running?")
				setRotateHook("play");
				document.getElementById("rotation-switch-container").style.pointerEvents = "auto";
			}

			setPickerHooks(picker_hook_proxy);
		}
	}

	const fetchNewDesign = async (hash, model) => {

		console.log("CHANGING THE USER DESIGN IN VERAPP", hash)
		const user_designs_ref = doc(dbx, 'user_designs', hash);
		const user_data = await getDoc(user_designs_ref);
		if (user_data.exists()) {
			let data = { id: user_data.id, ...user_data.data() };
			if (data.designer === model) {
				return({return_type:"200", return:data});
			} else {
				//ID not for this model;
				/*if (document.getElementById('input-warning')) {
					//console.log("@PRO", data);
					document.getElementById('input-warning').innerHTML = "That design is incompatible with this model!";
				}*/
				return ({return_type:"404", return:"That design is incompatible with this model!"})
			}
		} else {
			//ID invalid
			/*
			if (document.getElementById('input-warning')) {
				//console.log("@PRO", data);
				document.getElementById('input-warning').innerHTML = "That design does not exist!";
			}*/
			return ({return_type:"404", return:"That design does not exist!"})
		}
	}

	function dataURLtoBlob(dataURL) {
		const byteString = atob(dataURL.split(',')[1]);
		const ab = new ArrayBuffer(byteString.length);
		const ia = new Uint8Array(ab);
		for (let i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i);
		}
		return new Blob([ab], { type: 'image/png' });
	}

	function handleTextApply() {
		let logo_vars = userDesign.text;
		let elements = document.getElementsByClassName(`okd-logo-input-text`);
		for (const [key, element] of Object.entries(elements)) {
			let logo_id = element.id;
			//console.log("kollahar", logo_id);
			//Declare blank object due to be filled.
			logo_vars[logo_id] = {}
			//For every child of the relevant element.
			for (const [child_key, child_element] of Object.entries(element.children)) {
				//Imperfect, probably needs an object with more than just the content.
				logo_vars[logo_id]['string'] = child_element.value;

			}
		}

		//let return_var = logo_vars;
		setUserDesign(prevState => ({
			...prevState,
			text:{
				...logo_vars
			}
		}));
	}
	
	//OnClick event for opening and closing the colour palette
	function togglePicker(id, event) {
		//Gets the colour palette element
		let element = document.getElementById("logo-pickers");
		const classList = element.className.split(/\s+/);
		const areaActive = classList.find(className => className.startsWith('okd-active-area'));
		//console.log("Toggle picker", areaActive, id, selectedLogoPicker)
		//If the palette is visible and the ID of the input is the same as the , then hide it and return rotation to its original setting. // Essentially inactive for the time being, far too much trouble.
		if (!areaActive) {
			//console.log("Toggle picker", "if not active or matching")
			setSelectedLogoPicker(id)
			let allActiveElements = document.querySelectorAll('.okd-active-area .okd-active-area');
	
			// Iterate through the NodeList of all elements with 'okd-active-area'
			for (let activeElement of allActiveElements) {
				// Check if the current element is not the 'element' to retain the class
				if (activeElement !== element) {
					// Remove 'okd-active-area' from other elements
					activeElement.classList.remove('okd-active-area');
				}
			}
			// Add 'okd-active-area' to the 'element'
			element.classList.add('okd-active-area');
			//console.log("Toggle picker", "making following active", element)
		} else {
			//console.log("Toggle picker: Clicked to hide");
			setSelectedLogoPicker("")
			element.classList.remove('okd-active-area');
			//element.style.display = "none";
		}

		//Going up to the design-accordion-panel
		let des_accord = event.target.parentElement.parentElement.parentElement;
		//And one further
		let parent_accord = des_accord.parentElement
		//If the event already has the selected-picker-logo, remove it and the 'hidden-accordion' class. Else, add/switch them.
		if (des_accord.classList.value.includes('selected-picker-logo')) {
			des_accord.classList.value = des_accord.classList.value.replace('selected-picker-logo', '');
			
			//For all children
			for (let index = 0; index < parent_accord.children.length; index++) {
				const element = parent_accord.children[index];
				//Remove hidden accordion
				if (element.classList.contains('hidden-accordion')) {
					element.classList.remove('hidden-accordion');
				}
			}
		} else {
			let allSelectedElements = document.querySelectorAll('.selected-picker-logo');

			// Iterate through the NodeList of all elements with 'okd-active-area'
			for (let activeElement of allSelectedElements) {
				// Check if the current element is not the 'element' to retain the class
				if (activeElement !== element) {
					// Remove 'okd-active-area' from other elements
					activeElement.classList.remove('selected-picker-logo');
				}
			}

			des_accord.classList.value += ' selected-picker-logo';

			//For all children
			for (let index = 0; index < parent_accord.children.length; index++) {
				const element = parent_accord.children[index];
				//If the element is not the selected picker, and is actually an accordion, add hidden
				if (!element.classList.contains('selected-picker-logo') && element.classList.contains('design-accordion-panel')) {
					element.classList.add('hidden-accordion');
				}
			}
		}
	}

	//Function to remove other instances of a class or ID and place it on the element being clicked.
	function switchAttribute(attribute, type, event_element) {
	}

	//The function that uploads the preview to Firebase, and saves a preview in the process
	function uploadAndCapture(canvas, userID="", setPreviewSrc, storage, permit=false) {
			//console.log("Start of uploadAndCapture")
			const getURl = async(ref) => {
					const url = await getDownloadURL(ref)
					return url;
			}
			
			return new Promise((resolve) => {
					requestAnimationFrame(() => {
							const screenshot = canvas.toDataURL('image/png');
							
							if (userID !== "" && typeof userID !== "undefined" && permit) {
									// Convert dataURl to blob
									const blob = dataURLtoBlob(screenshot);
									const upload_ref = ref(storage,`/previews/${userID}/preview.png`)

									// Upload the Blob to Firebase Storage
									uploadBytes(upload_ref, blob)
									.then(() => {
											//console.log('File uploaded successfully');
											return getURl(upload_ref);
									})
									.then((data) => {
											//console.log("This is the resulting data:", data);
											setPreviewSrc(data);
											resolve();
									})
									.catch((error) => {
											console.error('Error uploading file: ', error);
											resolve(); // Resolve the promise even if there's an error
									});
							} else {
									//console.log("Using the data URL");
									setPreviewSrc(screenshot);
									resolve();
							}
							
							if (document.getElementById('preview-image')) {
								document.getElementById('preview-image').style.display = "block";
							}
					});
			});
	};

	const isObjectEmpty = (obj) => {
		return Object.keys(obj).length === 0 && obj.constructor === Object;
	};

	function changeDesign(e, id) {
		console.log("tny-220, changing design, colours are", userDesign.colours);
		let current_id = userDesign;
		let current_elements = document.querySelectorAll(".texture-selector .current-logo-selection")
		for (let index = 0; index < current_elements.length; index++) {
			const element = current_elements[index];
			element.classList.remove("current-logo-selection");
		}
		
		updateText.current = true; // JOSH
		updateLogos.current = true;// JOSH
		/*let new_userDesign = userDesign;
		new_userDesign.design = id
		new_userDesign.prev_design = current_id*/
		let new_userDesign = {
			...userDesign,
			design:id,
			prev_design: current_id
		};
		console.log("tny-220, The old and new colours:", userDesign.colours, new_userDesign.colours);
		console.log("The current ID will be", new_userDesign);
		setUserDesign(new_userDesign);
		e.target.classList.add("current-logo-selection");
	}
	
	//When a new User ID comes in from the input in functionality, fire this use effect.
	useEffect(() => {
		if (userHash) {
			console.log("echi-895, user hash:", userHash);
			let userHash_proxy = userHash.replace("OKD","").replace("EDI","").replace("#","");
			if(userHash_proxy !== initial_design.okd_hash){
				console.log("echi-895 above function", userHash_proxy, props.incoming.model, view);
	
				fetchNewDesign(userHash_proxy, props.incoming.model).then((data) => {
					if (data.return_type === "200") {
						console.log("echi-895 200", data);
						setUserDesign(data.return);
						// FIX PROBABLE
						currentHash = (view == "share" ? "OKD"+userHash_proxy : "EDI"+userHash_proxy);
						//if (userHash !== props.urls.user_design) {
						//	changeView("share");
						//}
					} else {
						console.log("echi-895, handling push state event", data);
						//changeView("edit");
	
						setTimeout(() => {
							if (document.getElementById('input-warning')) {
								//console.log("@PRO", data);
								document.getElementById('input-warning').innerHTML = data.return;
							}
						}, 700);
					}
				});
			}
		} else {
			console.log("echi-895, blank hash:", userHash);
		}
	}, [userHash])

	useEffect(() => {
		const handleHashChange = () => {
				//TEST HASHES
				//ZrjmO2h5xt3X913asjjO
				//GYQLSqRE2Ibh3w0zv3OU
				
			var newHash = sanitizeAndValidateHash(window.location.hash);
			console.log("echi-895 sanitising, currentHash",currentHash,newHash,view);
			if (newHash){
				if(currentHash != newHash){
					console.log("echi-895 sanitising, SHOULD QUESTION", newHash.substring(0,3), view);
					if (currentHash.substring(0,3) == 'OKD' && newHash.substring(0,3) == 'EDI' ) {
						currentHash = newHash;
						setView("edit");
						console.log("echi-895 sanitising, AAA ", newHash, view);
					}else if (currentHash.substring(0,3) == 'EDI' && newHash.substring(0,3) == 'OKD' ) {
						if(view == "saved"){
							setView("share");
							return;
						}
						if(view == "edited"){
							setUserHash(newHash);
							setView("share");
							return;
						}
						
						// NEED TO KNOW IF THE HASH WAS SET BY SAVE
						if( showSave.current === false ){
							setUserHash(newHash);

						}
						currentHash = newHash;
						setView("share");
					} else {
						console.log("echi-895 sanitising CCC " + newHash, view);
					}
					/*if (newHash.substring(0,3) == 'OKD' && view == "edit") {
						console.log("echi-895 sanitising, AAA ", newHash, view);
						setUserHash(newHash)
						setView("share");
					} else if(newHash.substring(0,3) == 'EDI' && view == "share") {
						console.log("echi-895 sanitising, BBB ");
						setView("edit");
					} else {
						console.log("echi-895 sanitising CCC " + newHash, view);
					}*/
				}
				let curH = currentHash.replace("OKD","").replace("EDI","").replace("#","")
				let newH = newHash.replace("OKD","").replace("EDI","").replace("#","")
				if(curH == newH) {
					// SAME
					if(view == "edited"){
						//setUserHash(newHash);
						//setView("share");
					}
				}else if(curH != newH) {
					// ONLY CHANGABLE VIA SAVE OR BACK OR INTERNAL LINK

					// SAVED
					if(view == "saved") {
						setView("share");
						return;
					}
					if(view == "edited"){
						//setUserHash(newHash);
						//setView("share");
					}
					// NEED TO LOAD DESIGN UNLESS IT WAS A SAVE
					if (newHash.substring(0,3) == 'OKD' && view == "edit") {
						//setUserHash(newHash);
						//setView("share");
					}/*
					if( showSave.current === false ){
						setUserHash(newHash);
						setView("share");
					}else if (newHash.substring(0,3) == 'OKD') {
						console.log("echi-895 sanitising, AAA ", newHash, view);
						setView("share");
					} else if(newHash.substring(0,3) == 'EDI') {
						console.log("echi-895 sanitising, BBB ");
						setView("edit");
					}*/
				} else {
					console.log("echi-895 sanitising DDD " , currentHash , newHash, view);
				}
			}
		};
	
		const handlePushStateEvent = () => {
			console.log("echi-895, handling push state event");
			handleHashChange(); // Call handleHashChange when custom event is triggered
		};
	
		window.addEventListener("hashchange", handlePushStateEvent);
		window.addEventListener("pushStateEvent", handlePushStateEvent);
	
		//return () => {
		//	window.removeEventListener("hashchange", handleHashChange);
		//	window.removeEventListener("pushStateEvent", handlePushStateEvent);
		//};
	}, []);

	useEffect(() => {
		const fetchDataAndProcess = async () => {
			let uploaded_proxy = [];
			let user_design = initial_design;
			let combined_logos = { ...userDesign["logos"], ...user_design["text"] };
	
			if (Object.values(combined_logos).length > 0 && user_design["logos"]) {
				setSelectedLogoPicker(Object.keys(user_design["logos"]).sort()[0]);
	
				for (const [key, value] of Object.entries(user_design["logos"])) {
					if (value.name && value.string) {
						try {
							// Perform asynchronous treatment operation
							const data_string_proxy = value.string;
							//const data_string_proxy = await applyTreatment(value.string, "removeWhite");
							uploaded_proxy.push({ "name": value.name, "data_string": data_string_proxy, "treatment": value.treatment, "parent": value.parent });
						} catch (error) {
							console.error('Error processing logo:', error);
							// Handle the error accordingly
						}
					}
				}
	
				if (uploaded_proxy.length > 0) {
					setUploadedImages(uploaded_proxy);
				} else {
					console.log("No uploaded images found");
				}
			} else {
				console.log("No logos found in user design");
			}
		};
	
		fetchDataAndProcess();
	}, [JSON.stringify(props.incoming.logos)]);

	//Fires whenever something changes in userDesign
	useEffect(() => {
		console.log("%cColour changed", "color:red; font-size:180%;");
		if (document.getElementById('preview-image')) {
			document.getElementById('preview-image').style.display = "none";
		}

		//updateModel.current = false;

		//setUpdateModel(false);
		showSave.current = true;
		setSavedMessage("");
		setPreviewSrc("");

		console.log("sup-2048 UD UF for view");
		//console.log("sup-2048 stringToHash\n", stringToHash(userDesign), "\n", stringToHash(initial_design));
		if (JSON.stringify(userDesign) !== JSON.stringify(initial_design)) {
			console.log("sup-2048 apparent success\n");
			setView("edited");
		} else {
			console.log("sup-2048 the else", JSON.stringify(userDesign) !== JSON.stringify(initial_design), "\n", userDesign, "\n===\n", initial_design)
		}
	}, [JSON.stringify({...userDesign})]);

	useEffect(() => {
		console.log("Function is definitely running, conditions:", userDesign);
		console.log("The current ID has become", userDesign);
		if(userDesign.prev_design === null || userDesign.design != userDesign.prev_design){ // THIS ONE IS AN OPPTOION ALBEIT BAD!
				// CHEK IF THE userDesign.design has been changed from previous
			//let cache_proxy = {...caches}
			//If the design is not in the user_cache, then fetch it
			let cache_index = returnIndex(caches.current.designs, userDesign.design)

			const fetchSvgStuff = async (cache_index) => {
				let cache_ref = caches.current;

				console.log("cache_index",cache_index);
		
				console.log("Crucially, caches", caches.current)
				//If the file is present in the designs
				if (cache_index > -1) {
					//If the value of SG is blank, undefined or otherwise falsy; seems a bit pointless, if this hasn't existed before
					if (!(caches.current.designs[cache_index].svg)) {
						// FETCH
						caches.current.designs[cache_index].svg = "fetching";
						const svgWidth = 4096 / 2;
						console.log("Crucially, we are here, kkd outside of the function.", caches.current, caches.current.designs[cache_index]);
						let fetched_svg = await fetchSVGs(userDesign.design, svgWidth, svgWidth/*, caches.current.designs[cache_index]*/, userDesign.colours);
						//console.log("Crucially, we are here, kkd with function result.\n", JSON.stringify(fetched_svg));
						
						caches.current.designs[cache_index] = {
							...caches.current.designs[cache_index],
							"svg":fetched_svg.svg,
							"colour_pickers":fetched_svg.colour_pickers
						};
						console.log("Crucially, we are here, inside of the function. \n", caches.current);
						console.log("Crucially, we are assembling 1", caches.current.designs[cache_index], caches.current);

					} else if (caches.current.designs[cache_index].svg === "fetching") {
						// FETCHING
					} else {
						// GOOD TO GO.
					}

					//caches.current = cache_ref;

					//return;
				} else {
					alert("Design not in allowed designs");
				}

				let design_overlay = caches.current.designs[cache_index].overlay;
				console.log("Is it the design?", caches.current);
				if (!(design_overlay in caches.current.overlays)) {
					let returned_overlay = await fetchOverlay(design_overlay);
					console.log("FTJ-88, so far outside the function", returned_overlay.svg)
					caches.current.overlays[design_overlay] = {"svg":returned_overlay.svg,"pickers":{"text_pickers":[...returned_overlay.text_pickers],"logo_pickers":[...returned_overlay.logo_pickers]}}
					//caches.current.overlays[design_overlay].svg = caches.current.overlays;
				}

				/*
					What's causing a delay?
					Not sure how the assembly of logos should work. Text and logos will always go together,
					will they not?
					So therefore, I suppose, there should be no 'colours' or 'overlay' intent in the
					assembly function: just 'overlay'. This would run both functions. Could query if
					they should be one function, but let's not go there just yet.
				*/
				
				console.log("assembleDesign call, fetchSvgStuff", userDesign.colours);
				let result = await assembleDesign(caches.current.designs[cache_index], caches.current.overlays[design_overlay], userDesign, buildObject, "all");

				console.log("FTJ-88, pickers", caches.current.overlays[design_overlay].pickers);

				let build_proxy = {
					...buildObject,
					textures: {
						...buildObject.textures,
						svg: {...buildObject.textures.svg}
					},
					cached_svgs: {
						...buildObject.cached_svgs,
					},
					colour_pickers: {
						...buildObject.colour_pickers,
						texture_colour_pickers: caches.current.designs[cache_index].colour_pickers
					},
					logo_text_pickers: {
						...caches.current.overlays[design_overlay].pickers
					}
				};

				build_proxy.textures.svg["exterior"] = new MeshPhysicalMaterial({ map: result.texture, side:FrontSide, emissive:0x000000,reflectivity: 0/*,specular: 0x000000, shininess: 0, color:state.colours[0],*/ });
				build_proxy.cached_svgs.design = result.design;
				build_proxy.cached_svgs.overlay = result.overlay;

				setBuildObject(build_proxy);
			}

			fetchSvgStuff(cache_index);
			incrementClass();
			console.log("tifnf After the function, cache", caches.current, buildObject);
		}
	}, [userDesign.design]);

	useEffect(() => {
		const colourUfFunction = async() => {
			let cache_index = returnIndex(caches.current.designs, userDesign.design)
			console.log("assembleDesign call, colourUfFunction", userDesign.colours);
			let coloured_svg = await assembleDesign(caches.current.designs[cache_index], caches.current.designs[cache_index], userDesign, buildObject, "colours")
			
			let new_texture = new MeshPhysicalMaterial({ map: coloured_svg.texture, side:FrontSide, emissive:0x000000,reflectivity: 0/*,specular: 0x000000, shininess: 0, color:state.colours[0],*/ });
			
			let test_texture = buildObject.textures.banding;

			console.log("tathenij, applyColours", coloured_svg)
			setBuildObject(prevState => ({
				...prevState,
				textures:{
					...prevState.textures,
					svg: {
						...prevState.textures.svg,
						"exterior":new_texture
					}	
				},
				cached_svgs: {
					...prevState.cached_svgs,
					"design":coloured_svg.design
				}
			}));
		}

		if (changedColour.current !== "start") {
			console.log("first the changed key is?", buildObject, changedColour, changedColour.current);
			let changed_picker = Object.keys(buildObject.colour_pickers).find(key => changedColour.current in buildObject.colour_pickers[key]);
			console.log("the changed key is?", changed_picker);

			if (changed_picker === "model_colour_pickers") {
				let mesh_name = buildObject.colour_pickers[changed_picker][changedColour.current].name;
				let mesh_object = props.config.meshes[mesh_name];
				let userColours = userDesign.colours

				let ccol = {
					color: "#000000",
					emissive:"#000000"
					/*specular: 0x000000, shininess: 0*/
				};
					
				//If there is a colour matching the part of the model in state, then save that colour
				if ( mesh_object.colour_id && mesh_object.colour_id.startsWith("#") ) {
					console.log("the changed key texture 1", mesh_object.colour_id);
					// USING bigJSON COLOUR
					ccol.color = mesh_object.colour_id;
				} else if ( mesh_object.colour_id && mesh_object.colour_id.startsWith("C") ) {
					console.log("the changed key texture 2", mesh_object.colour_id, userColours, userColours[mesh_object.colour_id]);
					//FOR USER EDITABLE COLOURS
					ccol.color = userColours[mesh_object.colour_id];
					if (mesh_object.colour_id) {
						let vcid = mesh_object.colour_id;
						// IF C#a/b/c
						let c_match = [...vcid.matchAll(/(C[\d]+)[a-z]+/gi)];
						if( c_match.length > 0 ) {
							vcid = c_match[0][1];
							ccol.color = userColours[vcid];
						} 
					}
				} else {
					// colour_id isn't defined in the bigJSON or its false // JOSH REVISIT
				}
				
				//If there is a material from the model for this particular part, then save it as a material.
				if ( mesh_object.texture ) {
					// Load texture
					const texture = new TextureLoader().load(mesh_object.texture);
					texture.flipY = false;
					//console.log("wsbdnr map", texture);
					ccol.map = texture;
					ccol.reflectivity = 1;
				}
				if ( mesh_object.roughness && mesh_object.physical ) {
					ccol.roughness = mesh_object.roughness;
				}
				console.log("the changed key texture", ccol);
				let saved_material;
				if ( mesh_object.physical ) {
					ccol.sheen = 0;
					ccol.roughness = mesh_object.roughness ? mesh_object.roughness: 0.5;
					//ccol.color = 0xff00ff
					saved_material = new MeshPhysicalMaterial(ccol);
				}else{
					//ccol.color = 0xff0000
					saved_material = new MeshPhongMaterial(ccol);
				}
				setBuildObject(prevState => ({
					...prevState,
					textures: {
						...prevState.textures,
						[mesh_name]: saved_material
					}
				}))
			} else if (changed_picker === "texture_colour_pickers") {
				colourUfFunction();
			}
		} else {
			console.log("first the changed key is? not happening here");
		}
		console.log("tny-220, the old colours", userDesign.colours);
	}, [userDesign.colours])

	useEffect(() => {
		const logosUfFunction = async() => {
			console.log("tny-220, logos uf, inside function", caches.current);
			//Get the index of the design, and the overlay from that design
			let cache_index = returnIndex(caches.current.designs, userDesign.design)
			let design_overlay = caches.current.designs[cache_index].overlay;
			console.log("assembleDesign call, logosUfFunction", userDesign.colours);
			let overlayed_svg = await assembleDesign(caches.current.designs[cache_index], caches.current.overlays[design_overlay], userDesign, buildObject, "overlay")

			let new_texture = new MeshPhysicalMaterial({ map: overlayed_svg.texture, side:FrontSide, emissive:0x000000,reflectivity: 0/*,specular: 0x000000, shininess: 0, color:state.colours[0],*/ });
			
			console.log("tathenij, applyLogos", overlayed_svg)
			setBuildObject(prevState => ({
				...prevState,
				textures:{
					...prevState.textures,
					svg: {
						...prevState.textures.svg,
						"exterior":new_texture
					}	
				},
				cached_svgs: {
					...prevState.cached_svgs,
					"overlay":overlayed_svg.overlay
				}
			}));
		}

		console.log("tny-220, logos UF", userDesign.logos);
		//If there are overlays to use
		if (Object.keys(caches.current.overlays).length > 0) {
			logosUfFunction();
		}
	}, [userDesign.logos, userDesign.text])

	useEffect(() => {
		//Stop it running if there's nothing different; mostly to stop it on the first go around
		if (JSON.stringify(userDesign["logos"]) !== JSON.stringify(initial_design["logos"])) {

			//Change the pictures, I think.
			console.log("Running logo uf", userDesign["logos"], initial_design["logos"])
			let elements = Object.values(document.querySelectorAll(".logo-picker-input"));
			//console.log("Running logo uf elements", elements);
			for (let index = 0; index < elements.length; index++) {
				const element = elements[index];
				//console.log("Running logo uf so far?", userDesign["logos"][element.name]);
				if (userDesign["logos"][element.name].string) {
					//console.log("Running logo uf endgame", element, (userDesign["logos"][element.name].string).substring(0,25))
					element.src = userDesign["logos"][element.name].string;
				}
			}
		}
	}, [JSON.stringify(userDesign["logos"])])



	if (showSave.current === true) { 
		console.log("The things needed to upload 0", userDesign);
		save_button = <NewSaveButton model={props.incoming.model} textureID={userDesign.design} ref_var={canvasRef.current} buttons={props.config.buttons} userDesign={userDesign} dataURLtoBlob={dataURLtoBlob} switchAttribute={switchAttribute} form={props.urls.form} userEmail={userEmail} tempCode={tempCode.current} uploadedImages={uploadedImages} showSave={showSave} setFunctions={{"setMessage":setSavedMessage, "setPreview":setPreviewSrc, "setUserEmail":setUserEmail, "setModalWindow":setModalWindow,"setView":setView}} />
	} else {
		save_button = `No save button, show save is ${showSave.current}`;
	}

	//If there is a hash and the parameter is set to share
	if (view && view === "share") {
		return (
			<>
				<section className="app-header">
					<RotationButton 
						rotate={rotateHook}
						setRotate={setRotateHook}
					/>
					<div className="main-canvas-column">
						<Canvas shadows /*camera={{ fov: 35, near: 1, far: 1000, position: [10,100,800] }}*/ width={650} height={420} ref={canvasRef}>
							<Environment preset="city" />
							<ambientLight intensity={0.7} />
							<spotLight intensity={0.5} angle={0.1} penumbra={1} position={[10, 100, 900]} castShadow />
							<spotLight 
								position={[0, 10, 0]}
								angle={Math.PI / 4}
								penumbra={0.2}
								intensity={1}
								distance={50}
								decay={2}
								color="white" // Set the color to red
								castShadow
							/>
							<ContactShadows resolution={2} position={[0, -10, 0]} opacity={0.5} scale={100} blur={1} far={0.8} />
							<Scene
								key="shga56"
								textures={buildObject.textures}
								json={props.config}
								model={props.incoming.model}
								models={props.models}
								//preview_var={previewTaken}
							/>
							<OrbitControls
								minPolarAngle={1} 
								maxPolarAngle={2} 
								enableZoom={true}
								minDistance={500}
								maxDistance={800}
								enablePan={false}
							/>
						</Canvas>
					</div>
				</section>
				<div style={{"padding":"0em 2em", "position":"absolute","top":"0px"}}>
					<h2>{currentHash.replace("OKD","SHARE ").replace("EDI","EDITING ")}</h2>
					<button className="black-button" onClick={() => {changeHash(setUserHash,"EDI")}}>Edit this design</button>
				</div>
			</>
		);
	} else {
		let debugHtml = '';
		if( props.config.debugRender ){
			debugHtml = <>
				<div className="okd-devtools">
					<div>
						<img src="" alt="The SVG for exterior" id="prevvess"></img>
						<img src="" alt="The SVG for interior" id="prevvess-2"></img>
						<img src="" alt="The SVG for logo" id="prevvess-3"></img>
					</div>
					<div>
						<div id="camera-coords" style={{fontSize:'10px',borderBottom:'1px solid black'}}></div>
						<div id="camera-speed" style={{fontSize:'10px',borderBottom:'1px solid black'}}></div>
						<div id="camera-notes" style={{fontSize:'10px',borderBottom:'1px solid black'}}></div>
					</div>
				</div>
			</>
		}

		let save_stuff;
		if (view == "edited") {
			console.log("sup-2048 return statement",view);
			save_stuff = <>
			<div className="collapsible-container">
				<div className="collapse-button" onClick={(e) => handleOpening({"snapFocus":snapFocus,"setSnapFocus":setSnapFocus}, e)}>Save</div>
				<div className="collapsible-area">
					{save_button}
					{savedMessage}
					<div id="okd-save-further-input"></div>
					<img alt="Preview of the product" id="preview-image" src={previewSrc} style={{display:"none", width:"100%"}}/>
				</div>
			</div>
			</>
		}else{
			console.log("sup-2048 return statement else ",view);

			save_stuff = <></>
		}
				
		//Returns the HTML layout of the page, w ith the canvas in there too. Canvas doesn't resize with the page after the first load, I think there's a setting for that, at least.
		return (
			<>
				<section className="app-header">
					<div className={"overall-container " + props.config.layoutClass}>
						<RotationButton rotate={rotateHook} setRotate={setRotateHook} />
						{Children.map(props.children, child =>
							child.type.name === 'UserIDInput' ? child : null
						)}
						<div className={"okd-tools-column "}>
							{debugHtml}
							<div className="collapsible-container">
								<div className="collapse-button" onClick={(e) => handleOpening({"snapFocus":snapFocus,"setSnapFocus":setSnapFocus}, e, 'designs')}>Designs</div>
								<div className="collapsible-area texture-selector">
									{pickerHooks["designs"]}
								</div>
							</div>
							<div className="collapsible-container">
								<div className="collapse-button" onClick={(e) => handleOpening({"snapFocus":snapFocus,"setSnapFocus":setSnapFocus}, e, 'colours')}>Colours</div>
								<div className="collapsible-area okd-pickers">
									{pickerHooks["colours"]}
								</div>
							</div>
							<div className="collapsible-container">
								<div className="collapse-button" onClick={(e) => handleOpening({"snapFocus":snapFocus,"setSnapFocus":setSnapFocus}, e, 'text')}>Text</div>
								<div className="collapsible-area okd-pickers" id="okd-text-input-container" style={{flexDirection:"column",gridGap:"1em"}}>
									<span style={{gap:"1em", display:"flex",flexDirection:"column"}}>
										{pickerHooks["text"]}
									</span>
									<button className="black-button" onClick={(e) => handleTextApply()}>Apply</button>
								</div>
							</div>
							<div className="collapsible-container">
								<div className="collapse-button" onClick={(e) => handleOpening({"snapFocus":snapFocus,"setSnapFocus":setSnapFocus}, e, 'logos')} id="logos-collapsible-picker">Logos</div>
								<div className="collapsible-area okd-pickers" id="okd-logo-input-container">
									<div style={{width:"100%"}} className="design-accordion-container">
										<span id="picker-hook-container-logos">
											{pickerHooks["logos"]}
										</span>
										<div id={"logo-pickers"} className="area-to-activate">
											<LogoInput ID={selectedLogoPicker} type={"logo"} uploadedImages={uploadedImages} setUploadedImages={setUploadedImages} userDesign={userDesign} setUserDesign={setUserDesign} switchAttribute={switchAttribute} isObjectEmpty={isObjectEmpty} canvasRef={canvasRef.current} />
										</div>
									</div>
								</div>
							</div>

							{save_stuff}

							<div className="collapsible-container disabled-container">
								<div className="collapse-button">SHARE</div>
							</div>
							<img alt="Preview of the product" id="preview-image" src={previewSrc} style={{display:"none", width:"100%"}}/>
						</div>
						{/* <PickerColumn debugRender={props.config.debugRender} model={props.incoming.model} rotateModel={rotateModel} setRotateModel={setRotateModel} haltRotation={haltRotation} updateModel={updateModel} setTheDesign={setTheDesign} userDesign={userDesign} setUserDesign={setUserDesign} uploadedImages={uploadedImages} setUploadedImages={setUploadedImages} switchAttribute={switchAttribute} canvasRef={props.canvasRef} pickers={model_textures.pickers} meshes={props.config.meshes} /> */}
						<div className="second-column">
							<Canvas shadows camera={{ fov: 35, near: 1, far: 1000, position: [0,100,600]}} width={650} height={420} ref={canvasRef}>
								<Environment preset="city" />
								<ambientLight intensity={0.7} />
								<spotLight intensity={0.5} angle={0.1} penumbra={1} position={[10, 100, 900]} castShadow />
								<spotLight 
									position={[0, 10, 0]}
									angle={Math.PI / 4}
									penumbra={0.2}
									intensity={1}
									distance={50}
									decay={2}
									color="white" // Set the color to red
									castShadow
								/>
								<ContactShadows resolution={2} position={[0, -10, 0]} opacity={0.5} scale={100} blur={1} far={0.8} />
								<Scene
									key="uascg"
									textures={buildObject.textures}
									json={props.config}
									model={props.incoming.model}
									models={props.models}
								/>
								<CameraControls 
				
									snapAngle={snapAngle}
									setSnapAngle={setSnapAngle}
				
									snapFocus={snapFocus}
									setSnapFocus={setSnapFocus}
				
									//rotationBrake={rotationBrake}
									//setRotationBrake={setRotationBrake}

									//rotateToPoint={rotateToPoint}
									//setRotateToPoint={setRotateToPoint}
				
									rotate={rotateHook}                    
									setRotate={setRotateHook}

									//setReady={setPreviewReady}
								/>
							</Canvas>
						</div>
						<div id="login-container">
							{modalWindow}
						</div>
					</div>
				</section>
			</>
		);
	}
}

export default Functionality;