let firestoreURlImport
//firestoreURlImport = 'http://localhost:5004/online-kit-designer/us-central1';
firestoreURlImport = 'https://us-central1-online-kit-designer.cloudfunctions.net';

/*if (process.env.NODE_ENV === 'development') {
	firestoreURlImport = process.env.REACT_APP_FIRESTORE_URL || 'http://localhost:5004/online-kit-designer/us-central1';
} else {
	firestoreURlImport = process.env.REACT_APP_FIRESTORE_URL || 'https://us-central1-online-kit-designer.cloudfunctions.net/';
}*/

export default firestoreURlImport