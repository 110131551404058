import { proxy, snapshot, useSnapshot } from "valtio";

const state = proxy({
  current: null,
  colours: {},
  textures: {
    t_shirt: {
      "240623-KELVIN-T_SHIRT_KELVIN-fabric_a": "/textures/240623-KELVIN-T_SHIRT_KELVIN-fabric_a_D.png",
      "240623-KELVIN-T_SHIRT_KELVIN-fabric_b": "/textures/240623-KELVIN-T_SHIRT_KELVIN-fabric_b_D.png",
      "240623-KELVIN-T_SHIRT_KELVIN-metal_a": "/textures/240623-KELVIN-T_SHIRT_KELVIN-metal_a_AO.png",
      "240623-KELVIN-T_SHIRT_KELVIN-metal_b": "/textures/240623-KELVIN-T_SHIRT_KELVIN-metal_b_AO.png",
      "240623-KELVIN-T_SHIRT_KELVIN-plastic": "/textures/240623-KELVIN-T_SHIRT_KELVIN-plastic_AO.png",
    },
    headgear_new: {
      'Interior': "/textures/HG_Model01.png",
      'String': "/textures/HG_Model01.png",
      'Chin_Strap': "/textures/HG_Model01.png",
      'Velcro': "/textures/HG_Model01.png",
      'Banding': "/textures/HG_Model01.png",
      'Helmet_Top': "/textures/HG_Model01.png",
      //'Helmet_Lower': "/textures/HG_Model01.png",
    }
  }
})

export default state;
