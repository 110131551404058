
	const treatments = {
		'removeBlack': { 'color': '000000', 'fuziness': 20 },
		'removeWhite': { 'color': 'FFFFFF', 'fuziness': 10 }
	};

	function imageDataLoop(imageData_param, treatment) {
		let imageData_proxy = imageData_param;
		for (let i = 0; i < imageData_proxy.data.length; i += 4) {
			const red = imageData_proxy.data[i];
			const green = imageData_proxy.data[i + 1];
			const blue = imageData_proxy.data[i + 2];
			const targetRed = parseInt(treatment.color.substr(0, 2), 16);
			const targetGreen = parseInt(treatment.color.substr(2, 2), 16);
			const targetBlue = parseInt(treatment.color.substr(4, 2), 16);
			if (
				Math.abs(red - targetRed) <= treatment.fuziness &&
				Math.abs(green - targetGreen) <= treatment.fuziness &&
				Math.abs(blue - targetBlue) <= treatment.fuziness
			) {
				imageData_proxy.data[i + 3] = 0;
			}
		}
		return imageData_proxy;
	}

	export { treatments, imageDataLoop }