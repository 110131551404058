import state from './state'; // Import the state variable
import { dbx } from '../firebase-config.js';
import { ref, getDownloadURL, uploadBytes, listAll } from 'firebase/storage';
import { collection, addDoc } from "firebase/firestore";
import { useEffect, useState } from 'react';

import { storage } from "../firestore-config.js";
import LoginModal from "./loginModal.js";
import firestoreURlImport from '../firestore_url.js';
import InquireModal from './inquireModal.js';

function NewSaveButton(props) {
    const [designSaved, setDesignSaved] = useState(false);

	console.log("underToppen", props.userDesign);
    // Determine Firestore URL based on environment
    let firestoreUrl = firestoreURlImport;
    
    function uploadIcons(userID="", data_string, name) {
		console.log("Start of uploadAndCapture")
		const getURl = async(ref) => {
				const url = await getDownloadURL(ref)
				return url;
		}
		
		return new Promise((resolve) => {
			requestAnimationFrame(() => {
				if (userID !== "" && typeof userID !== "undefined") {
					// Convert dataURl to blob
					const blob = props.dataURLtoBlob(data_string);
                    let identifier = getCurrentTime()
                    console.log(`Uploading to /icons/${userID}/${identifier}-${name}.png`)
					const upload_ref = ref(storage,`/icons/${userID}/${identifier}-${name}.png`)
					const file_ref = ref(storage,`/icons/${userID}`)

                    listAll(file_ref)
                        .then((result) => {
                            let match_count = 0;
                            const contents = result.items.map((item) => item.name);
                            for (const [key, value] of Object.entries(contents)) {
                                //If there's a file matching, then add to the match count
                                match_count += (value.includes(name)) ? 1 : 0
                            }
                            //If there are no matches, then proceed
                            if (match_count === 0) {
                                // Upload the Blob to Firebase Storage
                                uploadBytes(upload_ref, blob)
                                .then(() => {
                                        //console.log('File uploaded successfully');
                                        return getURl(upload_ref);
                                })
                                .then((data) => {
                                        resolve();
                                })
                                .catch((error) => {
                                        console.error('Error uploading file: ', error);
                                        resolve(); // Resolve the promise even if there's an error
                                });
                            } else {
                                let error_message = `${name} has already been uploaded<br>`;
                                if (!(document.getElementById('okd-save-further-input').innerHTML).includes(error_message)) {
                                    document.getElementById('okd-save-further-input').innerHTML += error_message;
                                }
                            }
                        })
                        .catch((error) => {
                            console.error('Error listing folder contents:', error);
                            throw error; // Rethrow the error for further handling
                        })
				} else {
						resolve();
				}
			});
		});
	};

    function getCurrentTime() {
        var now = new Date();
      
        var year = now.getFullYear().toString().slice(-2);
        var month = ('0' + (now.getMonth() + 1)).slice(-2);
        var day = ('0' + now.getDate()).slice(-2);
        var hours = ('0' + now.getHours()).slice(-2);
        var minutes = ('0' + now.getMinutes()).slice(-2);
        var seconds = ('0' + now.getSeconds()).slice(-2);
      
        return year + '-' + month + '-' + day + '.' + hours + ':' + minutes + ':' + seconds;
    }

    //Checks if all is fine for the user to save
    const verifyConditions = async () =>  {
        // Set up options for the fetch request
        console.log("save button: before sent");
        const options = {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({"email":props.userEmail, "temp_access":props.tempCode, "passcode":"n/a", "intent":"save_logos"}),
        };
        console.log("save button: awaiting response", JSON.parse(options.body), `${firestoreUrl}/verifyLogIn`);
		const response = await fetch(`${firestoreUrl}/newVerifyLogIn`, options);
        let response_json = await response.json();
        console.log(response_json);
        if (response_json.action === "email-window") {
            console.log("Running.", response_json.action)
            props.setFunctions.setModalWindow(<LoginModal intent="save" setModalWindow={props.setFunctions.setModalWindow} tempCode={props.tempCode} openModal={true} userEmail={props.userEmail} setUserEmail={props.setFunctions.setUserEmail} />);
        } else if (response_json.action === "proceed-to-save") {
            saveDesign(true);
        }
    }

    const saveDesign = async (withLogos=false) => {
        console.log("Saving with logos at the toppen", props.userDesign);
        const logo_entries = withLogos ? props.userDesign["logos"] : {};
        let save_object;

        save_object = props.userDesign;
        if (props.userEmail) {
            save_object.user = props.userEmail;
        } else {
            if (props.userDesign.user) {
                delete save_object.user
            }
        }
        save_object.design = props.textureID;
        console.log("Save objects for loop", save_object, props.userDesign);

        for (const [key, value] of Object.entries(save_object["logos"])) {
            console.log("Save objects", key);
            if (withLogos) {
                //logo_entries[key] = save_object["logos"][key]
                save_object["logos"][key].string = "";
            } else {
                save_object["logos"][key] = {"name":"","data_string":"","treatment":false, "parent":""};
            }
        }

        console.log("Save objects", save_object, logo_entries);

        /*//For all the values stored within logoData, old
        for (const [key, value] of Object.entries(props.logoData)) {
            console.log(key, value);
            //If the value is a text value
            if (key.includes("TEXT")) {
                text_entries[key] = value
            //Only consider logos if with logos, else don't bother, if the
            } else if (withLogos && key.includes("LOGO")) {
                let proxy_entries = value;
                delete proxy_entries.string;
                logo_entries[key] = proxy_entries;
            }
        }
        console.log("Before ...toppen", props.logoData);

        let save_object = {
            colours:JSON.parse(JSON.stringify(state.colours)),
            design:props.textureID,
            designer:props.model,
            text:text_entries
        }*/

        
        /*if (withLogos) {
            save_object.logos = {};
            save_object.logos = logo_entries;
            save_object.user = props.userEmail;
        }*/
        
        let new_hash = "";
        let share_buttons = [];
        let new_user_id = "";
        const ref_var = collection(dbx, 'user_designs');
        
        console.log("The brink of saving...toppen", save_object, logo_entries);
        console.log("The things needed to upload 1", props.uploadedImages);
        //console.log("The things needed to upload 2", logo_entries, props.userDesign["logos"], save_object);
        
        new_user_id = await addDoc(ref_var, save_object)  // Use addDoc to add a document to the collection
        //Save object 
        .then((docRef) => {
            console.log('Document written with ID: ', docRef.id);
            new_hash = `#OKD${docRef.id}`;
              
            props.setFunctions.setView("saved");
            
            window.history.pushState(null, null, new_hash);
    	    const pushStateEvent = new Event("pushStateEvent");
    	    window.dispatchEvent(pushStateEvent);

            //window.history.pushState(null, null, new_hash);

            console.log("Saved, use the hash " + new_hash + " to access")
            setDesignSaved(true);
            props.showSave.current = false;
            //Need to add the buttons here, somehow.
        })
        console.log("Saving with logos?", withLogos);
        if (withLogos === true) {
            for (const [key, value] of Object.entries(props.userDesign["logos"])) {
                console.log("Outside the if", key, value);
                if (key.includes("LOGO") && logo_entries[key] !== ""/* && value.name !== ""*/) {
                    let icon_string = "";
                    let icon_name = "";
                    
                    //If the value is a treatment
                    //if (value.treatment == true) {
                    console.log("SAVE PARENT: HAS PARENT")
                    for (let index = 0; index < props.uploadedImages.length; index++) {
                        const element = props.uploadedImages[index];

                        let uploaded_image_comparator = element.treatment ? element.parent : element.name

                        console.log("SAVE PARENT COMPARE:", uploaded_image_comparator, value.parent)
                        //if (uploaded_image_comparator === value.parent) {
                        console.log("SAVE PARENT: FOUND PARENT!", element)
                        icon_string = element.data_string;
                        icon_name = uploaded_image_comparator;
                        //}
                    }
                    /*} else {
                        console.log("In the else", value);
                        icon_string = logo_entries[key]
                        icon_name = value.name
                    }*/

                    console.log("Values for upload", icon_string, icon_name)
                    
                    if (icon_string && icon_name) {
                        console.log("Ought to be uploading.")
                        uploadIcons(props.userEmail, icon_string, icon_name)
                    } else {
                        console.log("Not uploadng:", icon_string, icon_name);
                    }
                }
            }
        }
        //setEmailInput(<></>);
    }
    
    let image_save;
    let proceed_buttons = <></>;

    if (designSaved) {
        proceed_buttons = <>
            <hr style={{width:"100%"}}></hr>
            <button className="black-button" onClick={() => {props.setFunctions.setModalWindow(<InquireModal setModalWindow={props.setFunctions.setModalWindow} form={props.form}/>)}}>Inquire</button>
            <button className="black-button" onClick={() => {console.log("There'll be some manner of process to happen here.")}}>Buy</button>
        </>;
    }
    
    // If there are uploaded images, then have option to save images.
    if (props.uploadedImages.length > 0) {
        return (
            <div className="okd-save-container">
                <button className="black-button" onClick={(e) => {saveDesign(false)}}>
                    Save without images
                </button>
                {/*<button className="black-button" id="image_save_button" onClick={(e) => {handleDetails(e)}}>
                    Save with images
                </button>*/}
                <button className="black-button" id="image_save_button" onClick={(e) => {verifyConditions()}}>
                    Save with images
                </button>
                <p id='okd-further-error' style={{color:"red"}}></p>
                {proceed_buttons}
            </div>
        )
    //Just plain old save.
    } else {
        return (
            <div className="okd-save-container">
                <button className="black-button" onClick={(e) => {saveDesign(false)}}>
                    Save
                </button>
                <hr style={{width:"100%"}} />
                <div id="okd-save-modal-further-input">
                </div>
            </div>
        )
    }
}

export default NewSaveButton