// Version 0.2.1
import "./App.css";
import { useState, useEffect, useMemo, useRef } from 'react';
import Functionality from "./functionality";
//import json from './bigJSON.json';
//import { getDocs, collection, where, query } from "firebase/firestore";
import { initializeApp } from 'firebase/app';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { fetchDoc } from './firebase-config.js';
import { fetchLogos } from './firestore-config.js';

import Error404 from './components/error404';
import firebaseConfig from './global-config.js';
import firestoreURlImport from './firestore_url.js';
import UserIDInput from "./components/useridinput";
import AdBanner from "./components/adBanner.js";
import { processMeshes } from "./components/ModelTextureLoader.jsx";

// Your web app's Firebase configuration
const app = initializeApp(firebaseConfig);

const storage = getStorage(app);

let page_url = new URL(window.location.href);
//let page_url = new URL("https://impsport.onlinekitdesigner.com/");

let firestoreUrl = firestoreURlImport;
let authorisation_key = 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjZmOTc3N2E2ODU5MDc3OThlZjc5NDA2MmMwMGI2NWQ2NmMyNDBiMWIiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJhY2NvdW50cy5nb29nbGUuY29tIiwiYXpwIjoiNjE4MTA0NzA4MDU0LTlyOXMxYzRhbGczNmVybGl1Y2hvOXQ1Mm4zMm42ZGdxLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwiYXVkIjoiNjE4MTA0NzA4MDU0LTlyOXMxYzRhbGczNmVybGl1Y2hvOXQ1Mm4zMm42ZGdxLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwic3ViIjoiMTA4MjU4Njc1NDA3OTA3MzYyNDAyIiwiaGQiOiJhc3RsZXltYXJrZXRpbmcuY29tIiwiZW1haWwiOiJqb3NoLnNob3Jyb2NrQGFzdGxleW1hcmtldGluZy5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiYXRfaGFzaCI6ImQwQ2swQ2RJNXFfWjFVOGo3a1JWbFEiLCJuYmYiOjE3MDk2NDIwMDksImlhdCI6MTcwOTY0MjMwOSwiZXhwIjoxNzA5NjQ1OTA5LCJqdGkiOiI2OWViZjdmOGNmNGM3YzUxMjdiYTcyMTVmOTgyOTAzMGZmYTJlNGU3In0.YIOMMiTBX1wgR03unXlf7e_eCpgIqlzwZmovDM00vbVb6gmdTvoTWIJFBWsu5QUFu7jM1Ze5dz-JcuQAsuJ5Hxzny6EhE5YEO__xwUOz9nqQDI5XMPRfgWaE6ajZUK_ra5akJtLQq1KLdPqAc4WcoMK4U2YffHm3WOmREedTuWWkv0n_x1BzOEJ30oE_p236BANWBV8Lvq6uHhOlt46rwxefEdpKNZxZ03KweYkFz1S9poKqtiJQMvNZunosNAUsi9UkCSDQarfpWmMtRbFfQSrbOKHSKuLPCFhU31R4Wf7I8riYrdad1xxjxjN3SZfBJu3h49IKKVe0KhQiUP2RWg';

//This controls the model loaded, I think
let url_parameters = page_url.searchParams;

function App() {
	const [returnObject, setReturnObject] = useState();
	/*let config = [];
	let domains = [];
	let urls = [];
	let models = [];
	let okd_hash = "new";
	let view = 'edit';*/
	
	let page_host = page_url.hostname;

	useEffect(() => {
		const verifyDomainCaller = async () => {
			let url_object = {
				href: page_url.href,
				protocol: page_url.protocol,
				host: page_url.host,
				hostname: page_url.hostname,
				port: page_url.port,
				pathname: page_url.pathname,
				search: page_url.search,
				hash: page_url.hash,
			};
			//console.log("Hello world 3")
			let option_body = {"local_hostname":page_host, "page_url":url_object};
			//console.log(option_body);
			
			const options = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization':authorisation_key
				},
				body: JSON.stringify(option_body),
				mode: 'cors' // Ensure you're using CORS mode
			};


			console.log(`${firestoreUrl}/secondDomainVerify`);
			const response = await fetch(`${firestoreUrl}/secondDomainVerify`, options);
			let component_response = await response.json();
			console.log("component_response",component_response);
			if (component_response.message === 200) {
				setReturnObject(<VerifiedApp {...component_response.data} />)
			} else if (component_response.message === 404) {
				setReturnObject(<Error404 message={component_response.data.message} />)
			}
		}

		verifyDomainCaller();

	}, [])

	return returnObject;
}

const getPreviewData = async (data) => {
	let preview_data = await fetchLogos(data, "icons");
	//Loop through the preview data
	console.log("getPreviewData returned data", data)
	for (let index = 0; index < preview_data.length; index++) {
		const element = preview_data[index];
		const preview_name = element.name;

		for (const [key, value] of Object.entries(data.logos)) {
			if (!key.toLowerCase().includes("logo")) {
				continue
			}

			//console.log("data_result 1", element.previewData);
			if (value && value.name === preview_name) {
				console.log("getPreviewData", data.logos, "|", element, index)
				data.logos[key].string = element.previewData;
			}
		}
	}
	return data
}

function VerifiedApp(props) {
	//let json_model_key;
	let json = {};

	props.config.debugRender = props.domains.debug
    props.config.layoutClass = props.domains.layout_class
        //"firestoreDoc": "vIHn9brWB4VccgojCX0s",
		//"firestoreDoc": props.config.model_name,
        //"real_key":"blinn8SG",
	props.config.buttons = props.config.buttons
	
	//json.default_colours = props.config.default_colours;
	//json.buttons = props.config.buttons;
	if (typeof props.config.meshes === "string") {
		let json_meshes = JSON.parse(props.config.meshes);
		props.config.meshes = json_meshes;
	} else {
		//json.meshes = props.config.meshes;
	}

	//const [view, changeView] = useState(props.view);

	/*if (url_parameters.get("view") === "share") {
		app_view = url_parameters.get("view")
	} else {
		app_view = "edit";
	}*/
	//let userDesign_init = {};

	// eslint-disable-next-line
	//const [json, setJson] = useState({});
	//const [selectedJSON, setSelectedJSON] = useState(json);
	//const [newUserID, setNewUserID] = useState();
	//const [incomingModel, setIncomingModel] = useState(props.models.location);
	//const [designOptions, setDesignOptions] = useState({});
	//const [logoOverlay, setLogoOverlay] = useState("");
	const [palettes, setPalettes] = useState({});
	const [stylesheet, setStylesheet] = useState("");
	const [initialDesign, setInitialDesign] = useState({...props.user_design});
	let incoming = {};
	//let first_load = useRef(true)
	//userDesign_init = props.user_design;
	console.log("verifiedApp ss--tttt",props);
	
	useMemo(() => {
		//If there's a initialDesign present with colours, then use those, else use the default.
		const colours = initialDesign.colours ? initialDesign.colours : props.config.default_colours; //Remove, use UD instead
		console.log("hhggg23534", props.urls.designs);

		incoming = {
			"model"        : props.urls.model,
			"colours"      : colours, //Remove, use UD instead
			"designs"      : props.urls.designs,
			"design_id"    : initialDesign.design, //Remove, use UD instead
			//"logo_overlay" : logoOverlay,
			"logos"		   : [initialDesign.logos, initialDesign.text], //Remove, use UD instead
			"palette"	   : palettes,
			"stylesheet"   : stylesheet
		};
	}, [JSON.stringify(initialDesign), palettes, stylesheet])
	
	useEffect(() => {
		/*fetchDoc("overlays", props.urls.model).then((fetched_docs) => { //DIVERSIFY LATER // Don't just get the headgear one
			setLogoOverlay(fetched_docs.overlay_svg);
		})*/

		let palette_proxy = palettes;
		for (const [key, value] of Object.entries(props.urls.palettes)) {
			console.log("picker reload loop", key, value, props.urls.palettes, palette_proxy, !palette_proxy.hasOwnProperty(key));
			if (!palette_proxy.hasOwnProperty(key)) {
				console.log("picker reload, in if");
				fetchDoc("palettes", value).then((palette) => {
					console.log("picker reload palettes", palette);
					palette_proxy[key] = JSON.parse(palette.colours);
				})
			}
		}

		console.log("picker reload, app", props.urls.palettes, palette_proxy)
		setPalettes(palette_proxy);

		if (props.domains.layout_class) {
			fetchDoc("stylesheets", props.domains.layout_class.replace("okd-","")).then((data) => { //Move to be included with domainVerify
				//console.log("Stylesheet data:",data.stylesheet);
				setStylesheet(data.stylesheet);
			})
		}
	}, [])

	let user_design_foundations = processMeshes(props.config.meshes, initialDesign.design, initialDesign.colours);

	if (initialDesign) {
		/*if (initialDesign.model) {
			setIncomingModel(initialDesign.model);
		}*/
		let data_proxy = initialDesign;
		getPreviewData(data_proxy).then((data_result) => {
			console.log("getPreviewData:",data_result);
			setInitialDesign(data_result);
		})
	}

	if (Object.values(incoming).length !== 0) {
		//console.log("MoVTru: about to return", incoming, initialDesign);
		//incoming.colours = initialDesign.colours //EMERGENCY MEASURE, FIX //Fixed, I think

		return (
			<>
				<div>
					<Functionality
						urls={props.urls}
						models={props.models}
						brand=""
						//incomingModel={incomingModel}
						config={props.config}
						key="ch765a"
						initialDesign={initialDesign}
						//user_id={userHash}
						//setUserHash={setUserHash}
						incoming={incoming}
						ud_found={user_design_foundations}
						//view_param={app_view}
						//view={view}
						//changeView={changeView}
					>
						<UserIDInput key="bgjotx" />
						<AdBanner key="gvcsjf" />
					</Functionality>
				</div>
			</>
		)
	}
}

export default App;