function UserIDInput(props) {
  function handleIDsubmit(e) {
    document.getElementById('input-warning').innerHTML = "";
    if (e.key === 'Enter') {
      let input = e.target.value;
      if (input.length > 10) {
        //console.log("Handling it: length is greater than 10");
        if (input.length === 20) {
						window.history.pushState(null, null, `#OKD${input}`);
            const pushStateEvent = new Event("pushStateEvent");
            window.dispatchEvent(pushStateEvent);
        }else{
          document.getElementById('input-warning').innerHTML = "Save ID is too short!";
        }
      } else {
        document.getElementById('input-warning').innerHTML = "Save ID too short!";
      }
    }
  }

    return (
        <div className="okd-user-design-input">
            Retrieve previous design:
            <input style={{marginBottom:"0", width:'-webkit-fill-available'}}placeholder="Enter your save code" id="custom-design-input" maxLength={20} onKeyDown={handleIDsubmit}/>
            <div id="input-warning"></div>
        </div>
    )
}

export default UserIDInput;