import { useRef } from "react"
import { Canvas } from "@react-three/fiber"
import { ContactShadows, Environment, OrbitControls } from "@react-three/drei"
import Cylinder3d from "./Cylinder3d";

function Error404(props) {
    const canvasRef = useRef();
    document.body.classList.add('okd-start-transition');

    return (
        <div className="app-header">
            <h1 style={{position:"absolute"}}>Oops!</h1>
			<h2 style={{margin:0}}>{props.message}</h2>
            <Canvas shadows camera={{ fov: 35, near: 1, far: 1000, position: [10,0,0] }} width={650} height={420} ref={canvasRef}>
                <Environment preset="city" />
                <ambientLight intensity={0.7} />
                <spotLight intensity={0.5} angle={0.1} penumbra={1} position={[10, 100, 900]} castShadow />
                <spotLight position={[0, 10, 0]} angle={Math.PI / 4} penumbra={0.2} intensity={1} distance={50} decay={2} color="white" castShadow />
                <ContactShadows resolution={2} position={[0, -10, 0]} opacity={0.5} scale={100} blur={1} far={0.8} />
                <Cylinder3d />
                <OrbitControls minPolarAngle={1} maxPolarAngle={2} enableZoom={true} minDistance={15} maxDistance={15} enablePan={false} />
            </Canvas>
        </div>
    )
}

export default Error404;