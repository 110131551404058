import { useEffect } from "react";

function RotationButton(props) {
	let halt_class = "";
	let checked_class = false;
	
	function toggleRotation(e) {
		//If the box is checked, then play
		if (e.target.checked) {
			console.log("kkjdf, rotation, the function, before setting as play", props.rotate);
			props.setRotate("play");
		} else {
			console.log("kkjdf, rotation, the function, before setting as stop", props.rotate);
			props.setRotate("stop");
		};
		console.log("kkjdf, rotation, the function, after setting", props.rotate);
	}

	console.log("kkjdf, rotation in button", props.rotate);
	
	//If true, then add class on button
	if (props.rotate === "pause") {
		halt_class = ` okd-halt`;
	}
	
	if (props.rotate !== "stop") {
		checked_class = true;
	}
	
	return(
		<div className="okd-rotation-toggler">
			<span className="switch-container">
				<p style={{fontSize:"20px"}}>Rotation</p>
				<label className="switch" id="rotation-switch-container">
					<input type="checkbox" checked={checked_class} name="rotate-checkbox" id="rotate-checkbox" onClick={(event) => {toggleRotation(event)}}/>
					<span className={"slider round"+halt_class}></span>
				</label>
			</span>
		</div>
		)
	}
	
	export default RotationButton;