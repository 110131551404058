//Firebase stuff
import { initializeApp } from 'firebase/app';
import { getDocs, getDoc, collection, where, query, doc, documentId, onSnapshot, getFirestore, orderBy, limit } from 'firebase/firestore';
import { ref, getDownloadURL } from 'firebase/storage';

import firebaseConfig from './global-config.js';

const app = initializeApp(firebaseConfig);
const dbx = getFirestore(app);

/*function fetchValue(rel_collection, options = {}) {
  let {where_var, orderBy_var, limit} = options;
  let query_var = collection(dbx, rel_collection);

  if (where_var) {
      if (where_var[0] instanceof Array) {
          // It's an array of array
          console.log(query_var);
          for (let w of where_var) {
              query_var = query_var.where(...w);
          }
      } else {
          query_var = query_var.where(...where_var);
      }
  }

  if (orderBy) {
      query_var = query_var.orderBy(...orderBy_var);
  }

  if (limit) {
      query_var = query_var.limit(limit);
  }

  return query_var
          .get()
          .then()
          .catch()
}*/

const fetchDocs = async (rel_collection,docIds) => {
  const q = query(collection(dbx, rel_collection), where(documentId(), "in", docIds));

  const query_snapshot = await getDocs(q);
  const docs = [];
  query_snapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    //console.log(doc.id, " => ", doc.data());
    docs.push({ id: doc.id, ...doc.data() });
  });

  return docs;
}

const fetchValue = async (rel_collection, options) => {
  let doc_ref = collection(dbx,rel_collection);
  //let where_params = [];
  //console.log("getting"+rel_collection)

  for (const option of options) {
    //console.log("Parameters:" , ...option, "in database:", rel_collection)
    doc_ref = query(doc_ref, where(option[0], option[1], option[2]));
  }

  const doc_snap = await getDocs(doc_ref);
  const ret = [];
  doc_snap.forEach((doc) => {
    ret.push({ id: doc.id, ...doc.data() });
  });
  //console.log(ret)
  return ret;
}

const fetchDoc = async (collection, value) => {
  const ref_var = doc(dbx, collection, value);
    let render_details = getDoc(ref_var).then((doc) => {
      if (doc.exists()) {
        //console.log("We're here,", doc.data())
        return {...doc.data()};
      } /*else {
        console.log("Nothing,",doc);
      }*/
    }).catch((error) => {
      console.error('Error getting document:', error);
    });

    return render_details;
}

export { fetchValue, fetchDocs, fetchDoc, dbx, getDoc, doc };