import { useState, useEffect } from 'react';
import state from './state'; // Import the state variable
import design_colours from '../colours.json';
import { fetchDoc } from '../firebase-config.js';

function Picker(props) {
	const [wasRotating, setWasRotating] = useState(false);
	const [titleElement, setTitleElement] = useState("");
	//const [palette, setPalette] = useState({});
	const [paletteElement, setPaletteElement] = useState([]);
	console.log("pickers reload", props.palettes, props.palette_id)

	useEffect(() => {
	//function generatePaletteElement() {
		let k = 0;
		let elements = [];
		for (const [key, value] of Object.entries(props.palettes[props.palette_id])) {
			let selected_class = ""
			//IF the current selected colour matches the current of the loop
			if (cleanAndLowerCase("#" + key) === cleanAndLowerCase(state.colours[props.id])) {
				setTitleElement(value.name);
				selected_class = "current-colour-selection"
			}
			//console.log("Palette:", key, value, props.palettes[props.palette_id])
			elements.push(
				<div
					className={"colour_cube " + k + ` ${selected_class}`}
					style={{ backgroundColor: "#" + key}}
					key={value.name + "_" + k}
					id={"colour_code_" + k}
					title={"#" + key}
					onClick={(e) => {
						changeColour("#" + key, value.name, e);
					}}
				></div>
			);
			k += 1;
		}
		setPaletteElement(elements);	
	//}
	}, [design_colours, state.colours, props.id, JSON.stringify(props.palettes[props.palette_id])]);
	

	//OnClick event for opening and closing the colour palette
	function togglePalette() {
		//Gets the colour palette element
		let element = document.getElementById(props.id+"-colour-palette");
		const classList = element.className.split(/\s+/);
		const areaActive = classList.find(className => className.startsWith('okd-active-area'));

		//Gets rotation switch
		let switch_element = document.getElementById("rotation-switch-container");

		//If the palette is visible, then hide it and return rotation to its original setting.
		if (areaActive) {
			element.classList.remove('okd-active-area');

			switch_element.style.pointerEvents = "auto";
			//element.style.display = "none";
			//props.setSnapFocus({"x":0,"y":0,"z":0})
			
			console.log("kkjdf, rotation if?", props.rotate);
			//Used to be '=== pause' but there was an error of unknown origin, the bad solution was to just have it check if it's anything but 'stop'
			if (props.rotate !== "stop") {
				console.log("kkjdf, rotation inside if?", props.rotate);
				props.setRotate("play");
			}
			console.log("kkjdf, rotation after if?", props.rotate);
			//If the model is paused, then continue, else it'll be stopped and there's no need to interfere.
		} else {
			console.log("kkjdf, rotation else?", props.rotate);
			if (props.rotate !== "stop") {
				console.log("kkjdf, rotation inside else?", props.rotate);
				props.setRotate("pause");
			}
			console.log("kkjdf, rotation after else?", props.rotate);
			switch_element.style.pointerEvents = "none";
			let allActiveElements = document.querySelectorAll('.okd-active-area .okd-active-area');

			// Iterate through the NodeList of all elements with 'okd-active-area'
			for (let activeElement of allActiveElements) {
				// Check if the current element is not the 'element' to retain the class
				if (activeElement !== element) {
					// Remove 'okd-active-area' from other elements
					activeElement.classList.remove('okd-active-area');
					if(props.snapFocus !== false){
						//props.setSnapFocus({"x":0,"y":0,"z":0})
					}
				}
			}

			// Add 'okd-active-area' to the 'element'
			element.classList.add('okd-active-area');
		    //console.log("Rotating?", props.mesh);
			/*if (typeof props.mesh !== "undefined" && "angle" in props.mesh) {
			    //console.log("Should snap to", props.mesh.angle)
			    //console.log("Should focus on", props.mesh.focus)
				////props.setSnapAngle(props.mesh.angle)
				//props.setSnapAngle(props.mesh.angle)
				//props.setSnapFocus(props.mesh.focus)
				//props.setRotationBrake(0.9);
				//props.setRotateToPoint(true);
				//props.setRotateModel(true);
			} else {
			    //console.log("Not snapping.");
			}*/
		}
	}

	//When a colour is clicked in the palette, this runs.
	function changeColour(hex, title, e) {
		//Saves colour to userDesign, thus cascading into everything else the colours are relevant to.
		let ud_proxy = {
			...props.userDesign,
			colours: {...props.userDesign.colours}
		};
		ud_proxy.colours[props.id] = hex;
		props.setUserDesign(prevDesign => ({
			...prevDesign,
			colours: {...prevDesign.colours, [props.id]: hex}
		}));

		props.changedColour.current = props.id
		//props.setUpdate("true");

		document.getElementById("input-"+props.id).style.backgroundColor = hex;
		document.getElementById("rotation-switch-container").style.pointerEvents = "auto"
		//Restore original rotation setting
		setTitleElement(title);
		/*props.setRotateToPoint(false);*/
		document.getElementById("rotate-checkbox").checked = wasRotating;
		let allActiveElements = document.querySelectorAll('.okd-active-area .okd-active-area');

		let palete_elements = e.target.parentElement.children;
		//console.log(palete_elements);
		for (let index = 0; index < palete_elements.length; index++) {
			const element = palete_elements[index];
			if (element.classList.contains("current-colour-selection")) {
				element.classList.remove("current-colour-selection");
			}
		}
		e.target.classList += " current-colour-selection";
	}
	

	function cleanAndLowerCase(str) {
		return str.replace(/^\s+|\s+$/gm, '').toLowerCase();
	}

	return (
		<>
			
			<div className="design-accordion-panel">
				<div className="colour-picker-container">
					<span>
						<strong data-prod-id={props.id}>{(props.title).replace(/_/g, " ")+` (${props.id})`}</strong><br />
						<i>{titleElement}</i>
					</span>
					<span className="colour-picker-input" id={"input-"+props.id} style={{backgroundColor:props.userDesign.colours[props.id]}} onClick={togglePalette}></span>
				</div>
				<div id={props.id+"-colour-palette"} className="area-to-activate">
					<div className="colour-palette">
						{paletteElement}
					</div>
				</div>
			</div>
		</>
	);
};

export default Picker;