import { useState, useRef, useEffect } from "react";
import { treatments, imageDataLoop } from './treatments'


function LogoInput(props) {
	const [updateIcons, setUpdateIcons] = useState(false);
	const [basePickers, setBasePickers] = useState([]);
	const [treatmentPickers, setTreatmentPickers] = useState([])
	let logo_picker = [];
	const idRef = useRef(props.ID);

	function handleUpload(e) {
		console.log("Handling uploads, maybe ought to be blank:", props.uploadedImages)
		let proxy_uploads = typeof props.uploadedImages == "object" ? props.uploadedImages : [];
		const fileInput = e.target;
		
		if (fileInput.files.length > 0) {
			const file = fileInput.files[0];
			const reader = new FileReader();
		
			reader.onloadend = () => {
				const img = new Image();
				img.src = reader.result;
		
				img.onload = () => {
					const canvas = document.createElement('canvas');
					const ctx = canvas.getContext('2d');
					const maxWidth = 500;
					const maxHeight = 500;
			
					let newWidth, newHeight;
			
					if (img.width > img.height) {
						newWidth = maxWidth;
						newHeight = (img.height * maxWidth) / img.width;
					} else {
						newHeight = maxHeight;
						newWidth = (img.width * maxHeight) / img.height;
					}
			
					canvas.width = newWidth;
					canvas.height = newHeight;
			
					ctx.drawImage(img, 0, 0, newWidth, newHeight);
			
					const base64String = canvas.toDataURL('image/png').split(',')[1];
					let regex_filename = (file.name).toLowerCase().replace(/\..+$/, '').replace(/[^A-Z0-9\.-]+/ig, '-');
					let new_entry = {
						name: regex_filename,
						data_string: 'data:image/png;base64,' + base64String,
						treatment:false,
						temp: true,
						parent:regex_filename
					}
					// Adjust the rest of your code as needed
					proxy_uploads.push(new_entry);
					console.log("Setting uploaded images logoinput", proxy_uploads)
					props.setUploadedImages(proxy_uploads);
					//console.log("UploadedImages After uploading.", props.uploadedImages)
					setUpdateIcons(!updateIcons);
					showTreatments(new_entry.data_string, new_entry.name)
					/*setTimeout(() => {
						//console.log("UploadedImages After timeout.", props.uploadedImages)
					}, 500);*/
				};
			};

			reader.readAsDataURL(file);
		}
	}

	function removeIcon(element_id, picker_type="base") {
		console.log("Uploaded images in removeicon",props.uploadedImages);
		let remove_pickers_proxy;
		if (picker_type === "treatment") {
			remove_pickers_proxy = treatmentPickers;

			let treatment_index = remove_pickers_proxy.findIndex(treatment => treatment.key === element_id );
			console.log("Removing element:", element_id, remove_pickers_proxy, treatment_index);
	
			delete remove_pickers_proxy[treatment_index];
	
			setTreatmentPickers(remove_pickers_proxy);
		} else if (picker_type === "base") {
			remove_pickers_proxy = basePickers;
			console.log("picker_Type removing pickers", remove_pickers_proxy)
			let remove_uploaded_proxy = props.uploadedImages;
			let base_index = remove_pickers_proxy.findIndex(treatment => treatment.key === element_id );
			let uploaded_index = remove_uploaded_proxy.findIndex(treatment => treatment.parent === element_id );
			
			console.log("Removing picker element:", element_id, remove_pickers_proxy, base_index);
			console.log("pfd removing icon from the array, key and array:", uploaded_index, remove_uploaded_proxy, element_id);
			
			delete remove_pickers_proxy[base_index];
			delete remove_uploaded_proxy[uploaded_index];

			console.log("pfd removing icon from the array, about to set as", remove_uploaded_proxy);
			setBasePickers(remove_pickers_proxy);
			props.setUploadedImages(remove_uploaded_proxy);
			setUpdateIcons(!updateIcons);
		}
	}
	
	async function showTreatments(string, orig_name) {
		let treatment_proxy = {};
	
		// Function to load an image and apply treatment
		function loadImageAndApplyTreatment(src, treatment, treatment_name) {
			return new Promise((resolve, reject) => {
				const img = new Image();
				img.crossOrigin = 'Anonymous';
				img.onload = function () {
					const imageWidth = img.width;
					const imageHeight = img.height;
					const canvas = document.createElement('canvas');
					canvas.width = imageWidth;
					canvas.height = imageHeight;
					const context = canvas.getContext('2d');
					context.clearRect(0, 0, imageWidth, imageHeight);

					context.drawImage(img, 0, 0, imageWidth, imageHeight);
					let imageData = context.getImageData(0, 0, context.canvas.width, context.canvas.height);
					imageData = imageDataLoop(imageData, treatment);
					
					context.putImageData(imageData, 0, 0);
					const base64 = canvas.toDataURL('image/png');
					
					const modifiedImage = <img className={"texture-selector-box treatment " + treatment_name} style={{color:"red"}} src={base64} name={treatment_name} key={treatment_name} id={treatment_name} onClick={(e) => {changeLogo(e, {"data_string": base64, "name": treatment_name, "treatment": true, "parent": orig_name})}} ></img>;
					treatment_proxy[treatment_name] = modifiedImage;
					resolve();
				};
				img.src = src;
			});
		}
	
		// Load and apply treatment for each treatment type
		for (const treatmentName in treatments) {
			if (!treatments.hasOwnProperty(treatmentName)) continue;
			const treatment = treatments[treatmentName];
			//console.log("The new treatment name, ", orig_name, treatmentName)
			const className = orig_name + "_" + treatmentName;
			await loadImageAndApplyTreatment(string, treatment, className);
		}
	
		// Return the populated treatment_proxy object
		return treatment_proxy;
	}
	
	function treatmentExists(arrays, name) {
		let return_value;
		//console.log(array);
		for (const [key, array] in arrays) {
			if (array && array.length > 0) {
				return_value = Array.from(array).some(
					(child) => {if('id' in child["props"]){child["props"].id.includes(name)}} //child.props.id.contains(logo_name) // Assuming the treatment key is used as the element ID
				);
			} else {
				return_value = false;
			}
		}
		return return_value;
	}

	function selectTreatment(name, iteration) {
		if (iteration < 6) {
			//If one cannot find the treatment picker
			if (!document.getElementById(name)) {
				//console.log("ST-55j func: No treatment, waiting");
				setTimeout(() => {
					selectTreatment(name, iteration+1)
				}, 100);
			} else {
				console.log("ST-55j func: Treatment, clicking", document.getElementById(name), name);
				let treatment = document.getElementById(name);
				treatment.click()
			}
		}
	}
	
    function getDateTime() {
        var now = new Date();
      
        var year = now.getFullYear().toString().slice(-2);
        var month = ('0' + (now.getMonth() + 1)).slice(-2);
        var day = ('0' + now.getDate()).slice(-2);
        var hours = ('0' + now.getHours()).slice(-2);
        var minutes = ('0' + now.getMinutes()).slice(-2);
        var seconds = ('0' + now.getSeconds()).slice(-2);
      
        return year + month + day + hours + + minutes + seconds;
    }

	function changeLogo(e, params) {
		// Copy the logo data
		const updatedLogoData = {...props.userDesign["logos"]};
		// Update the specific logo entry
		updatedLogoData[idRef.current] = {
			...updatedLogoData[idRef.current],
			"string": params.data_string,
			"name": params.name,
			"treatment": params.treatment,
			"parent": params.parent,
		};
		console.log("Applying image, find it as follows", updatedLogoData[idRef.current], updatedLogoData)
		// Update the state with the modified logo data
		let userDesign_proxy = {...props.userDesign, logos:{...props.userDesign.logos}};
		userDesign_proxy.logos = updatedLogoData;
		props.setUserDesign(userDesign_proxy);
		/*props.setUserDesign(prevDesign => ({
			...prevDesign,
			logos: updatedLogoData
		}));*/
		let current_elements = document.querySelectorAll(`#${props.ID}-alt-logos .current-logo-selection`);
		//console.log("ST-55j elements:", current_elements);
		for (let index = 0; index < current_elements.length; index++) {
			const element = current_elements[index];
			//console.log("ST-55j element removals:", element);
			element.classList.remove("current-logo-selection");
		}

		e.target.classList.add("current-logo-selection");
	}	

	useEffect(() => {
		//This should sort out the 'icon selected' stuff.
		//setIcon(props.ID)
		idRef.current = props.ID;
		//console.log("New ID - component", props.ID, idRef);
		
		//console.log("ST-55j:", props.logoData, props.ID, props.logoData[props.ID]);
		if (typeof props.userDesign["logos"][props.ID] !== "undefined") {
			let combined_pickers = basePickers.concat(treatmentPickers);
			let treatment_key = props.userDesign["logos"][props.ID].name;
			//console.log("ST-55j: a new input is opened", combined_pickers, treatment_key, props.ID);
			if (Object.values(combined_pickers).some(e => treatment_key === e.key)) {
				//console.log("ST-55j uf loop matches: A match was found", treatment_key)
				selectTreatment(treatment_key, 0);
			} else {
				console.log(`ST-55j uf loop matches: The key ${treatment_key} is not in`, Object.values(props.userDesign["logos"]))
				let current_elements = document.querySelectorAll(`#${props.ID}-alt-logos .current-logo-selection`);
				//console.log("ST-55j elements:", current_elements);
				for (let index = 0; index < current_elements.length; index++) {
					const element = current_elements[index];
					//console.log("ST-55j element removals:", element);
					element.classList.remove("current-logo-selection");
				}
			}
		} else {
			console.log("ST-55j, probably closed", props.ID);
		}
	}, [props.ID, basePickers, treatmentPickers])
	
	useEffect(() => {
		console.log("The UploadedImages uf toppen", props.ID, props.uploadedImages);
		//Create logo picker if there are uploaded images
		if (!props.isObjectEmpty(props.uploadedImages) && props.ID) {
			let treatment_container = document.getElementById(props.ID + "-alt-logos");
			//let proxy_pickers = basePickers;
			let proxy_base_pickers = [];
			let proxy_trea_pickers = [];
			//console.log("UploadedImages", props.uploadedImages);
			for (const [key, value] of Object.entries(props.uploadedImages)) {
				console.log("The UploadedImages uf value", value);
				let selected_class = ""

				//If the 'parent' is not false
				let logo_name = value.parent === false ? value.name : value.parent
				//console.log("The logo name:", logo_name, value);
				//let logo_name = value.name

				//console.log("Picker values,", proxy_base_pickers, logo_name)
				let pickerExists = treatmentExists(proxy_base_pickers, logo_name);
				
				//console.log(logo_name, pickerExists);
				//If does not exist, append.
				if (!pickerExists) {
					let picker_element = <div key={logo_name} id={logo_name}><img className={"texture-selector-box uploaded-image "+selected_class} style={{color:"red"}} src={value.data_string} name={logo_name} key={logo_name} id={logo_name} onClick={(e) => {changeLogo(e, {"data_string":value.data_string, "name":logo_name, "treatment":false, "parent":false})}} ></img><span className="logo-removal-button" onClick={(e) => {removeIcon(logo_name, "base")}}>x</span></div>;
					proxy_base_pickers.push(picker_element) 
				}
				
				setBasePickers(proxy_base_pickers);

				showTreatments(value.data_string, logo_name).then((treatments) => {
					//console.log("Are we even here still?", Object.entries(treatments));

					for (const [treat_key, treat_value] of Object.entries(treatments)) {
						//console.log("Are we even here still TREATMENT:", treat_key, treat_value);

						let treatment_exists = treatmentExists(proxy_trea_pickers, treat_key);
						if (!treatment_exists) {
							proxy_trea_pickers.push(<div key={treat_key}>{treat_value}<span className="logo-removal-button" onClick={(e) => {removeIcon(treat_key, "treatment")}}>x</span></div>);
						}
					}
					//console.log("treatmentPickers:", proxy_trea_pickers);
					setTreatmentPickers(proxy_trea_pickers);
				})
				.catch(error => {
					console.error("An error occurred:", error);
				});
			}
		}
	}, [JSON.stringify(props.uploadedImages), props.ID])
	
	if (props.type == "logo") {
		return (
			<>
				<div className="design-accordion-panel">
					<div className="okd-larger-logo-container">
						<div className="colour-palette" id={props.ID+"-alt-logos"}>
							{basePickers}
							{treatmentPickers}
						</div>
						<hr style={{display:"none",margin:"1em 0"}}></hr>
						<input type="file" name="okd-logo-upload" id="okd-logo-upload" onChange={handleUpload} />
					</div>
				</div>
			</>
		)
	}
}

export default LogoInput;