// Version 0.3.0
import { TextureLoader, MeshPhysicalMaterial, MeshPhongMaterial, SRGBColorSpace, BackSide} from "three"
import { ref, getDownloadURL } from 'firebase/storage';

import { storage } from '../firestore-config';
import { fetchDoc } from '../firebase-config.js';
import { treatments, imageDataLoop } from './treatments'


//New MLT
/* This part should be found in Functionality, I think.
	//NEW FUNC: FETCH AND SAVE SVG
	//Get SVG data whenever the address or the content is updated(?)
	useEffect(() => {
		if ("d"+svg_key in return_object.designs) {
			setSvgContent(return_object.designs["d"+svg_key]);
		} else if (typeof svg_key !== 'undefined' ) {
			const fileRef = ref(storage, `gs://online-kit-designer.appspot.com/designs/${svg_key}/artwork.txt`);
			// Make a GET request to fetch the SVG content
			getDownloadURL(fileRef)
			.then((url) => {
				// Use the URL to fetch the content of the .txt file
				// You can use standard JavaScript methods like fetch or axios to read the content.
				fetch(url).then((response) => response.text()).then((data) => {
						// Handle the content of the .txt file here
						if (!return_object.designs["d"+svg_key]) {
							return_object.designs["d"+svg_key] = {};
						}
						if (data !== "") {
							return_object.designs["d"+svg_key].svg = data;
						}
						const designIndex = designs.findIndex(design => design.id === svg_key);
						console.log("FTJ-88, before if", designIndex, designs[designIndex])
						if (designIndex !== -1 && designs[designIndex].overlay) {
							console.log("FTJ-88, so far")
							fetchDoc("overlays", designs[designIndex].overlay).then((fetched_docs) => {
								console.log("FTJ-88, fetching overlay", designs[designIndex].overlay)
								return_object.designs["d"+svg_key].overlay = fetched_docs.overlay_svg;
								setSvgContent(return_object.designs["d"+svg_key])
							})
						} else {
							console.log("FTJ-88, so far gone")
							return_object.designs["d"+svg_key].overlay = default_logoOverlay;
							setSvgContent(return_object.designs["d"+svg_key])
						}
					}).catch((error) => {
						console.error('Error fetching file content:', error);
					});
			})
			.catch((error) => {
				console.error('Error getting download URL:', error);
			});
		} else {
			console.log("ddsfdg36525 else");
		}
	}, [svg_key]);

*/


function applyTreatment(src, treatmentName) {
	console.log("89ie-ddt jjhs",treatmentName, src);
	if (treatmentName in treatments) {
		return new Promise((resolve, reject) => {
			const img = new Image();
			img.crossOrigin = 'Anonymous';
			img.onload = function () {
				const imageWidth = img.width;
				const imageHeight = img.height;
				const canvas = document.createElement('canvas');
				canvas.width = imageWidth;
				canvas.height = imageHeight;
				const context = canvas.getContext('2d');
				context.clearRect(0, 0, imageWidth, imageHeight);
		
				context.drawImage(img, 0, 0, imageWidth, imageHeight);
				let imageData = context.getImageData(0, 0, context.canvas.width, context.canvas.height);
				const treatment = treatments[treatmentName];
				//console.log("89ie-ddt jjhs",treatment, treatmentName, treatments);
				if (treatment) {
					imageData = imageDataLoop(imageData, treatment);
				}
				context.putImageData(imageData, 0, 0);
				
				// Returning data URL inside resolve
				resolve(canvas.toDataURL('image/png'));
			};
			img.onerror = function () {
				reject(new Error('Failed to load image ' + src));
			};
			img.src = src;
		});
	}
}

function fetchInterior(design_id, svgWidth, svgHeight) {
	//The process of actually getting the interior may vary.
	const fileRef = ref(storage, `gs://online-kit-designer.appspot.com/designs/${design_id}/interior.txt`);
	// Make a GET request to fetch the SVG content
	getDownloadURL(fileRef)
	.then((url) => {
		// Use the URL to fetch the content of the .txt file
		// You can use standard JavaScript methods like fetch or axios to read the content.
		fetch(url).then((response) => response.text()).then((data) => {
			if (data !== "") {
				let interior_SVG = data;
				//IF the SVG doesn't have a height or width attribute in the first 250 characters. If the attribute does not exist then it is added and subsequently filled in. If it does then do nothing. It either has a value already or has the template, which will be sorted. If neither, SVG has a problem.
				if (interior_SVG.search(/<svg .+ (?:height=)/gm)) {
					interior_SVG = interior_SVG.replace('<svg ', `<svg height="{{svgHeight}}" `)
				}
				
				if (interior_SVG.search(/<svg .+ (?:width=)/gm)) {
					interior_SVG = interior_SVG.replace('<svg ', `<svg width="{{svgWidth}}" `)
				}
				
				//Add height and width to the SVG
				interior_SVG = interior_SVG.replaceAll('{{svgWidth}}', svgWidth);
				interior_SVG = interior_SVG.replaceAll('{{svgHeight}}', svgHeight);
				
				let svgDataUri = `data:image/svg+xml;base64,${btoa(interior_SVG)}`;
				const svg = new TextureLoader().load(svgDataUri, (texture) => {

					//Fixes the colours
					svg.colorSpace = SRGBColorSpace;

					interior_SVG = interior_SVG.replaceAll('{{svgLogo}}', "");
					let svgDataUriUpdated = `data:image/svg+xml;base64,${btoa(interior_SVG)}`;
					texture.image.src = svgDataUriUpdated;
					if (document.getElementById("prevvess-2")) {
						document.getElementById("prevvess-2").src = svgDataUriUpdated;
					}
					texture.needsUpdate = true;
				});
				
				svg.flipY = false; // Flips texture
				svg.flipX = true; // Flips texture
				
				return svg;
				//mesh_colours["svg"].push(
			}
		})
	})
}

//function fetchSVGs(design_id, svgWidth, svgHeight/*, design_object*/, userColours) {
const fetchSVGs = async (design_id, svgWidth, svgHeight/*, design_object*/, userColours) => {
    /*
	
		This function is probably going to be a mix of the useEffect and some of the code that follows
		
		AT PRESENT:
		If the details of a design have not already been fetched, then the useEffect (UF) goes and gets and saves them whenever a new design is desired.
		Every single time the component is re-rendered, the raw svg content returned by the UF is processed, adding a width and filling out the templates and so on.

		This function will probably come somewhere in between

		IT OUGHT TO:
		Run every time the user chooses a design that hasn't been saved
		Fetch the SVG from the database and download it, all that stuff
		If absent:
			Add a background (identified with the 'C0' colour code, may change)
			Add a height and a width (maybe not with a placeholder? Height may well be fixed)
		Run a RegEx on the SVG to identify all the placeholders and use them to build up a picker array and probably a stylesheet with placeholders, which is probably added to the SVG

		[
			Not sure where the overlay stuff comes in. If we're only fetching here, it might make sense to get the
			overlay later, when dealing with colours and so on, but the overlay SVG still has to be sitting around
			somewhere. It might be an idea to just fetch them alongside this function, if the design's overlay
			hasn't yet been loaded and isn't waiting in a variable of its own
		]

		AND RETURN:
			- A blank SVG
			- The pickers
			Both will be saved to an object. Then, whenever they're used in the future, the pickers and the raw SVG will be retrieved and used, instead of running this entire rigmarole from top to bottom again.

			In another variable, if a new overlay has been loaded, save the overlay SVG and related information for the text and logo pickers.
	*/

    let return_object = {}
	let colour_code;
	let region_name;
    let get_file;

	if ( typeof design_id !== 'undefined' ) {
		const fileRef = ref(storage, `gs://online-kit-designer.appspot.com/designs/${design_id}/artwork.txt`);
		// Make a GET request to fetch the SVG content
        try {
		    let url = await getDownloadURL(fileRef)
            let response = await fetch(url);
            let data = await response.text();
            
            if (data !== "") {
                let design_svg = data
                let svg_stylesheet = '';
                let colour_pickers = {};

                //If there is no background colour, then add one.
                if (design_svg.indexOf("{C0}") === -1) {
                    design_svg = design_svg.replace('<g', `<g id="Base Colour"><rect x="0.5" class="okd-base-colour" width="${svgWidth}" height="${svgHeight}"/></g><g`)
                    design_svg = design_svg.replace('</style>', `.okd-base-colour{fill:{C0} /*Base Colour*/;}</style>`)
                }
        
                // SVG COLOUR REGIONS
                let SVG_matches = [...design_svg.matchAll(/<.+?id="(C\d+)".+?data-name="([^"]+)"([^>]+)?>/gm)];
        
                if (SVG_matches.length !== 0) {
                    // eslint-disable-next-line
                    for (const [index, element] of Object.entries(SVG_matches)) {
                        //If the match is an ID, but isn't a layer.
                        colour_code = element[1];
                        region_name = element[2];
                        if( !colour_pickers[colour_code] ){
                            //console.log("mtl adding pickers, colour code 1", colour_code)
                            colour_pickers[colour_code] = {name:region_name,col:colour_code};
                            svg_stylesheet += `#${colour_code}{fill:${userColours[colour_code]};}`
                        }
                    }
            
                    //Wrap CSS in style tags
                    svg_stylesheet = `<!-- OKD style --><style type="text/css">${svg_stylesheet}</style>`;
            
                    //Add style tag to the CSS // only in this conditional because otherwise there's no new CSS tag to add.
                    design_svg = design_svg.replace('</svg>', svg_stylesheet + '</svg>');
                }
                
                //If no object, create one; probably no longer needed
                /*if (!design_object) {
                    design_object = {"colour_pickers":{},"svg":""};
                }*/

                //Create texture using the SVG // ATTACH RESOLUTION TO DATABASE
                //const svgWidth = 4096 / 2;
                //const svgHeight = svgWidth;


                //IF the SVG doesn't have a height or width attribute in the first 250 characters. If the attribute does not exist then it is added and subsequently filled in. If it does then do nothing. It either has a value already or has the template, which will be sorted. If neither, SVG has a problem.
                if (design_svg.search(/<svg .+ (?:height=)/gm)) {
                    design_svg = design_svg.replace('<svg ', `<svg height="${svgHeight}" `)
                }

                if (design_svg.search(/<svg .+ (?:width=)/gm)) {
                    design_svg = design_svg.replace('<svg ', `<svg width="${svgWidth}" `)
                }

                //Add height and width to the SVG
                //design_svg = design_svg.replaceAll('{{svgWidth}}', svgWidth);
                //design_svg = design_svg.replaceAll('{{svgHeight}}', svgHeight);
                
                return_object.colour_pickers = colour_pickers;
                //console.log("fetchSVGs, actually about to set the design object as", design_svg)
                return_object["svg"] = design_svg;
                console.log("fetchSVGs, actually have set the design object", return_object)

                console.log("FTJ-88 at the end of fetchSVGs", return_object)
                return return_object;
            }
            //return return_object;
        } catch (error) {
			console.error('Error getting download URL:', error);
        }
	} else {
		console.log("ddsfdg36525 else");
	}
    console.log("FTJ-88 Crucially, we are here, kkd with function the end of fetchSVGs", return_object)
    return "return_object 1";

}

const fetchOverlay = async (design_overlay) => {
    let return_object = {"svg":"","text_pickers":[],"logo_pickers":[]};
    //OVERLAY STUFF, FIT IN SOMEWHERE
    //const designIndex = designs.findIndex(design => design.id === design_id);
    let overlay_svg = await fetchDoc("overlays", design_overlay);
    if (overlay_svg === "") {
        return false;
    }
    return_object.svg = overlay_svg.overlay_svg;
	let text_matches = [...return_object.svg.matchAll(/\{(TEXT_\d+)\}/gm)];
	let logo_matches = [...return_object.svg.matchAll(/\{(LOGO_\d+)\}/gm)];

	if (text_matches.length !== 0) {
		// eslint-disable-next-line
		for (const [index, match] of Object.entries(text_matches)) {
			//If the match is an ID, but isn't a layer.
            console.log("FTJ-89 MATCH", match[1]);
            return_object["text_pickers"].push(match[1]);
		}
	}

    if (logo_matches.length !== 0) {
		// eslint-disable-next-line
		for (const [index, match] of Object.entries(logo_matches)) {
			//If the match is an ID, but isn't a layer.
            console.log("FTJ-89 MATCH", match[1]);
            return_object["logo_pickers"].push(match[1]);
		}
	}

    console.log("FTJ-88, so far", return_object.svg, design_overlay);
    return return_object;
    
}

const applyLogos = async (overlay_svg, userLogos) => {
    if (overlay_svg) {
        console.log("tny-220, inside if", overlay_svg);
        let logo_matches = [...overlay_svg.matchAll(/\{(LOGO_\d+)\}/gm)];
        //Only continue if there are any matches, else there's no point
        if (logo_matches.length < 0) {
            console.log("tny-220, quitting because length is", logo_matches.length);
            return;
        }
            
        //// JOSH HERE
        console.log("tny-220, the function call, matches ", logo_matches.length);
        // NEED TO REFRESH THE IMAGE THAT GETS LOADED ONCE overlay_svg CHANGES
        if (logo_matches.length !== 0) {
            const logoMatchFunction = async () => {
                console.log("~~~~~~\ntny-220 start", userLogos);
                for (const [index, element] of Object.entries(logo_matches)) {
                    let match_key = element[1];
                    let data_string = "NONE!";
                    console.log("tny-220 loop", index, element, match_key);
                    //If the match_key is in logos
                    if (userLogos[match_key]) {
                        console.log("tny-220 if 1", userLogos[match_key].string);
                        //And it has a string (merge with above?)
                        if(userLogos[match_key].string){
                            console.log("tny-220 if 2");
                            data_string = userLogos[match_key].string;
                            //If the logo in UD is a treatment
                            if (userLogos[match_key].treatment){
                                console.log("tny-220 if 3");
                                let treatment_var = userLogos[match_key].name.replace(userLogos[match_key].parent+"_","");// i.e. "removeWhite"
                                console.log("tny-220 apply treatment", userLogos[match_key].treatment, treatment_var /*data_string.substring(0,35)*/);
                                let data_string_proxy = await applyTreatment(data_string, treatment_var);
                                console.log("tny-220 data_string_proxy", data_string_proxy);
                                data_string = data_string_proxy;
                            //If NOT a treatment - a raw image - update the preview
                            }else{
                                console.log("tny-220 preview", element);
                                if (document.getElementById("prevvess-3")) {
                                    document.getElementById("prevvess-3").src = data_string;
                                }
                            }
                            overlay_svg = overlay_svg.replaceAll(`{${match_key}}`, data_string);
                        }
                    }
                    overlay_svg = overlay_svg.replaceAll(`{${match_key}}`, data_string);
                    /*if (!return_object["logo-text-pickers"].includes(element[1])) {
                        console.log("combined_element", element[1]);
                        return_object["logo-text-pickers"].push(element[1]);
                    }*/
                }
                console.log("~~~~~~\ntny-220 end");
                //return [overlay_svg, return_object];
            }
    
            console.log("tny-220, the function call, before ", overlay_svg);
            return logoMatchFunction().then(() => {
                console.log("tny-220, the function call, after 1", overlay_svg);
                return overlay_svg;
            });
    
            console.log("tny-220, the function call, after 2", overlay_svg);
        }
    }
}

function newApplyLogos(overlay_svg, userDesign) {
    if (overlay_svg) {
        let logo_matches = [...overlay_svg.matchAll(/\{(LOGO_\d+)\}/gm)];
    
        if (logo_matches.length !== 0) {
            // eslint-disable-next-line
            for (const [index, element] of Object.entries(logo_matches)) {
                //If the match is an ID, but isn't a layer.
                let match_key = element[1];
                if(userDesign["logos"][match_key]){
                    if (userDesign["logos"][match_key].treatment) {
                        console.log("tny-220, match key, but treatment", userDesign["logos"][match_key]);
                    } else {
                        console.log("tny-220, match key", userDesign["logos"][match_key]);
                        //let data_string = userDesign["text"][match_key].string;
                        overlay_svg = overlay_svg.replaceAll(`{${match_key}}`, userDesign["logos"][match_key].string);
                    }
                }
            }
        }
        return overlay_svg;
    }
}

function applyText(overlay_svg, userDesign) {
    if (overlay_svg) {
        let text_matches = [...overlay_svg.matchAll(/\{(TEXT_\d+)\}/gm)];
    
        if (text_matches.length !== 0) {
            // eslint-disable-next-line
            for (const [index, element] of Object.entries(text_matches)) {
                //If the match is an ID, but isn't a layer.
                let match_key = element[1];
                if(userDesign["text"][match_key]){
                    let data_string = userDesign["text"][match_key].string;
                    overlay_svg = overlay_svg.replaceAll(`{${match_key}}`, data_string);
                }
                /*if (!return_object["logo-text-pickers"].includes(element[1])) {
                    console.log("combined_element", element[1]);
                    return_object["logo-text-pickers"].push(element[1]);
                }*/
            }
        }
        return overlay_svg;
    }
}

function applyColours(design_svg, userColours, picker_details) {
    let local_svg = design_svg;
	let colour_code;
	let region_name;

	let named_matches = [...local_svg.matchAll(/\{(C\d+)\}.+?\/*([^\*]+)\*\//gm)];
	let nameless_matches = [...local_svg.matchAll(/\{(C\d+)\}/gm)];
	let style_matches = [...named_matches, ...nameless_matches];
	
	if (style_matches.length !== 0) {
        console.log("Crucially, applyColours, got matches", style_matches);
		// eslint-disable-next-line
		for (const [index, element] of Object.entries(style_matches)) {
			//If the match is an ID, but isn't a layer.
			colour_code = element[1];
			if (typeof element[2] !== 'undefined') {
				region_name = element[2];
			} else {
				region_name = "NEW Colour " + colour_code.replace("C","");
			}
	
				//console.log("mtl adding pickers, colour code 2", colour_code)
				picker_details[colour_code] = {name:region_name,col:colour_code};
				//console.log("mtl colours", userColours, colour_code)
                console.log("Crucially, applyColours, should be replace", colour_code, "with", userColours[colour_code]);
				local_svg = local_svg.replaceAll(`{${colour_code}}`, userColours[colour_code]);
			
		}
	}

    return local_svg;
}

const assembleDesign = async (design_cache, overlay_cache, userDesign, buildObject, intent="all") => {
    let coloured_svg;
    let updated_overlay = "";
    let assembly_design_svg = "";
    let modified_assembly_design_svg = "";
    let assembly_overlay_svg = "";
    let return_object = {};
    console.log("assembleDesign SVG", design_cache);
    //let local_design = design_cache;
    //let local_overlay = overlay_cache;
    
    switch (intent) {
        case "all":
        case "colours":
            console.log("tny-220, adding these colours to the SVG:", userDesign.colours);
            coloured_svg = applyColours(design_cache.svg, userDesign.colours, design_cache.colour_pickers);
            //console.log("assembleDesign, coloured SVG", coloured_svg);

            if (intent !== "all") {
                console.log("assembleDesign, returning because intent equals", intent, design_cache)
                break;
            }
        case "overlay":
            console.log("tny-220 top of overlay:", userDesign, overlay_cache.svg);
            //applyLogos
            updated_overlay = applyText(overlay_cache.svg, userDesign);
            console.log("tny-220 after apply text:", updated_overlay, userDesign.logos);
            updated_overlay = await applyLogos(updated_overlay, userDesign.logos)
            //updated_overlay = newApplyLogos(updated_overlay, userDesign)
            console.log("tny-220 after apply logo:", updated_overlay);
            
            if (intent !== "all") {
                break;
            }
    
        default:
            break;
    }


    //Design thing
    //If the intent is neither for colours or for all, then get the cached design
    if (intent !== "colours" && intent !== "all") {
        assembly_design_svg = buildObject["cached_svgs"].design;
    } else {
        //console.log("tny-220, selecting coloured SVG:", userDesign.colours, coloured_svg);
        assembly_design_svg = coloured_svg;
    }

    //Overlay thing
    //If intent is to change colours and thus leave the overlay alone
    if (intent === "colours") {
        assembly_overlay_svg = buildObject["cached_svgs"].overlay;
    //If intent is not colours, then you'll need a new overlay for the assembly no matter what
    } else {
        assembly_overlay_svg = updated_overlay;
        console.log("tny-220, in the if", assembly_overlay_svg);
    }

    console.log("tny-220, before collating together\n", assembly_design_svg.substring(0,500));
    //Add in the overlay at the end, thus putting it on top.
    modified_assembly_design_svg = assembly_design_svg.replace('</svg>', assembly_overlay_svg + '</svg>')
    modified_assembly_design_svg = modified_assembly_design_svg.replaceAll('{{svgLogo}}', "");
    console.log("tny-220, collated together\n", modified_assembly_design_svg.substring(modified_assembly_design_svg.length-500));
    
    console.log("Assembly colours overlay:", intent, assembly_overlay_svg, updated_overlay);
    
    let svgDataUri = `data:image/svg+xml;base64,${btoa(modified_assembly_design_svg)}`;
    //Loading the image which may be present in the SVG
    const svg_texture = new TextureLoader().load(svgDataUri, (texture) => {
        texture.flipY = false;
        texture.colorSpace = SRGBColorSpace;
        texture.image.src = svgDataUri;
        texture.needsUpdate = true;
        if (document.getElementById("prevvess")) {
            document.getElementById("prevvess").src = svgDataUri;
        }
    });

    /*
    const svg_texture = new TextureLoader().load(svgDataUri, (texture) => {
        svg_texture.colorSpace = SRGBColorSpace;
        modified_assembly_design_svg = modified_assembly_design_svg.replaceAll('{{svgLogo}}', "");
        let svgDataUriUpdated = `data:image/svg+xml;base64,${btoa(modified_assembly_design_svg)}`;
        texture.image.src = svgDataUriUpdated;
        
        texture.needsUpdate = true;
    });
    */

    //svg_texture.flipY = false; // Flips texture
    //FOR THE SVG
    //mesh_colours["svg"].push(new MeshPhysicalMaterial({ map: svg, side:FrontSide, emissive:0x000000,reflectivity: 0/*,specular: 0x000000, shininess: 0, color:state.colours[0],*/ }));

    //console.log("tny-220, with overlay?", assembly_design_svg);

    //console.log("assembleDesign, coloured SVG return", svg_texture);
    return_object = {"texture":svg_texture,"design":assembly_design_svg, "overlay":assembly_overlay_svg};
    return return_object;
}

/* SOMEWHERE IN THE MIX:

	Set pickers according to meshes. Maybe the first time around wherever these are deployed,
	as a part of the loop that calls the interior function.

*/

/* WHAT IS THE USER CACHE GOING TO BE?
    The 'user_cache' will essentially be a replacement for 'model_textures'.
    Current ideas go something like this:
    {
        "designs":{
            "design_1": {
                "svg": '...the raw SVG string',
                "colour_pickers": [
                    '...here would be the things used to generate non-model colour pickers.'
                ]
            }
        },
        "overlays": {
            "overlay_1": {
                "svg": '...the raw SVG string',
                "text-logo_pickers" : [
                    '...potentially two variables, generating pickers for logos and text in the overlay. These might be objects in and of themselves'
                ]
            }
        }
    }

*/

/* On the first time, possibly even in verifiedApp with the results passed down */
function processMeshes(meshes, design_id, userColours) {
    console.log("processMeshes", meshes);
    let return_object = {"pickers":{},"textures":{},"svg":{}};

    //May need to accomodate for the possibility of having multiple SVGs on a model.
    let interior_svg = "";
    let interior_material = "";
    
    //Runs through the meshes, adding to the picker arrays, potential interior setup
    for (const [index, mesh] of Object.entries(meshes)) {
        if (mesh.colour_id && mesh.colour_id.match(/(C[\d]+)/gi)) {
            let colour_code = mesh.colour_id;
            let region_name = mesh.name || index; // Default to index if mesh.name is falsy
            let add_region_name = false;
    
            // Check if the colour code matches a certain pattern
            let c_match = [...colour_code.matchAll(/(C[\d]+)[a-z]+/gi)];
            if (c_match.length > 0) {
                add_region_name = true;
                colour_code = c_match[0][1];
            }
    
            // Check if the colour code already exists in picker_details
            if (!return_object["pickers"][colour_code]) {
                return_object["pickers"][colour_code] = { name: region_name, col: colour_code };
            } else if (add_region_name) {
                // If add_region_name is true, concatenate region_name to the existing name
                return_object["pickers"][colour_code].name += region_name;
            }
        }
    
        if (mesh.twoSided) {
            interior_svg = fetchInterior(design_id, (4096 / 2), (4096 / 2));
            interior_material =  new MeshPhysicalMaterial({ map: interior_svg, emissive: 0x000000, side: BackSide });
            return_object["svg"]["interior"] = interior_material;
        }
    }

    //The second meshes loop, merge with the above, eventually
    for (const [key, value] of Object.entries(meshes)) {
		let ccol = {
			color: "#000000",
			emissive:"#000000"
			/*specular: 0x000000, shininess: 0*/
		};
			
		//If there is a colour matching the part of the model in state, then save that colour
		if ( value.colour_id && value.colour_id.startsWith("#") ) {
			// USING bigJSON COLOUR
			ccol.color = value.colour_id;
		} else if ( value.colour_id && value.colour_id.startsWith("C") ) {
			//FOR USER EDITABLE COLOURS
			ccol.color = userColours[value.colour_id];
			if (value.colour_id) {
				let vcid = value.colour_id;
				// IF C#a/b/c
				let c_match = [...vcid.matchAll(/(C[\d]+)[a-z]+/gi)];
				if( c_match.length > 0 ) {
					vcid = c_match[0][1];
					ccol.color = userColours[vcid];
				} 
			}
		} else {
			// colour_id isn't defined in the bigJSON or its false // JOSH REVISIT
		}
		
		//If there is a material from the model for this particular part, then save it as a material.
		if ( value.texture ) {
			// Load texture
			const texture = new TextureLoader().load(value.texture);
			texture.flipY = false;
			//console.log("wsbdnr map", texture);
			ccol.map = texture;
			ccol.reflectivity = 1;
		}
		if ( value.roughness && value.physical ) {
			ccol.roughness = value.roughness;
		}
		if ( value.physical ) {
			ccol.sheen = 0;
			ccol.roughness = value.roughness ? value.roughness: 0.5;
			//ccol.color = 0xff00ff
			return_object["textures"][key] = new MeshPhysicalMaterial(ccol);
		}else{
			//ccol.color = 0xff0000
			return_object["textures"][key] = new MeshPhongMaterial(ccol);
		}
	};

    return return_object;
};

//processMeshes(props.config.models, model_pickers);

//Whenever the user chooses a new design
/*useEffect(() => {
    //If the design is not in the user_cache, then fetch it
    if (!userDesign.design in user_cache["designs"]) {
        const svgWidth = 4096 / 2;
		//const svgHeight = svgWidth;
        fetchSVGs(userDesign.design, svgWidth, svgWidth, user_cache)
    }
    //Set the data used for this design - similar to the two-pronged 'svgContent' object
    setDesignSvg(user_cache["designs"][userDesign.design]);
    //Run the assembly function
    assembleDesign(user_cache["designs"][userDesign.design], user_cache["overlays"][user_cache.overlay], userDesign);

    //Similar one somewhere for overlays
}, [userDesign.design])*/


/*
Need to think through how exactly this is going to work: specifically,
 1. How we deliver the assembled textures to the scene - what replaces 'mesh_colour', and how do we fill it?
 2. How rebuilding the texture when something changes works.

 So, 1:
    The main spanner in the works here comes, I think, from the fact that some of
    this is going to happen in VerifiedApp. From that, we'll be passing down the
    model pickers, which will never change (String, banding, etc.), and possibly an
    interior SVG. Question is, what variable do we put them in?

    So onto that. What feeds the Scene and Pickers and so on? Well, we previously cited
    'user_cache' as a replacement for model_textures. That distinction is better suited
    for this concept, however. 'user_cache' is best seen as, well, a container for all
    the things that could/have been deployed before and may be referred back to again.
    This variable is a collection of things taken from that cache, edited as needed,
    and thrown together. So, as an idea:

    {
        "textures":{
            svg:[],
            etc...

            The replacement of the "colours" object in 'model_textures', with the stuff for mesh
            textures, SVG or otherwise. May need to be keyed in a way that makes a limited update easy,
            but really any change in colour is going to require the texture to reload anyway, so it's
            hardly necessary.
        },
        "colour_pickers":{
            Pickers kept separate because it's makes for easier updating of the texture pickers.
            "model_colour_pickers": {
                The pickers that come from the non-SVG parts of the model
            },
            "texture_colour_pickers": {
                The pickers that are based on the incumbent design, and thus can change.
            }
        }.
        "logo_text_pickers":{
            "logo_pickers": {
                Stuff used to generate logo pickers
            },
            "text_pickers": {
                Stuff used to generate text pickers
            }
        },
        "cached_svgs": {
            "design": '...',
            "overlay":'...'

            Here goes the changed SVG files so that if one changes but not the other
            i.e., updating a color or a logo, respectively, then only one of the SVGs
            has to be rebuilt, and then can be integrated with the other.
        }
    }

    From verified app, props are passed down. 'model_pickers' goes into 'model_colour_pickers', and
    if it's found, the interior SVG is put into the svg array in 'textures', where the outer SVG will
    also end up.

    2. I think rebuilding the SVG will follow on largely from what we've just laid out, actually.
    When something is changed, we'll have a useEffect looking at each of the parts - colour, logos, etc.
    and from there, we can make limited changes to the big variable.

    But still, need to think about how this assembly function works. I think that the raw SVG is passed
    into it from the cache BUT THE CHANGES INSIDE THE FUNCTION DO NOT APPLY TO THE CACHE. Alongside
    the SVG, we pass in the 'intent'. This can be used to regenerate the entire thing, otherwise to
    target the function to regenerate certain parts. IF the intent is to regenerate the main design,
    then overlay goes untouched, and is fetched from the build object's cached bit when being compiled.
        

*/
export { applyTreatment, processMeshes, fetchSVGs, fetchOverlay, applyLogos, applyText, applyColours, assembleDesign }