import firestoreURlImport from '../firestore_url.js';
/*

This will come after the user has saved. Probably has to save with logos but we can make provision for other eventualities.
The user clicks the "Inquire" button after saving. This pops up a modal window with a form in it, the contents of which end up... elsewhere.
This should probably be the modal.

*/

function InquireModal(props) {
    let data = JSON.parse(props.form.json);
    let form_elements = [];
    for (const element of data) {
        //The most necessary data; don't continue without it
        if (element.tag && element.name) {
            let form_element;
            let label_element;

            if (element.label) {
                //console.log("yes label.");
                label_element = <label htmlFor={element.name} form="okd-inquiry-form">{element.label}</label>
            }

            switch (element.tag) {
                case "input":
                    let minmax = element.minmaxlen ? [...element.minmaxlen] : [false, false];
                    console.log("label?", label_element);
                    form_element = <>{label_element}<input
                        form="okd-inquiry-form"
                        type={element.type}
                        name={element.name}
                        id={element.name}
                        placeholder={element.placeholder}
                        required={element.required}
                        minlength={minmax[0]}
                        maxlength={minmax[1]}
                    /></>;

                    form_elements.push(form_element);
                    break;
                case "textarea":
                    console.log("gentest, in case textarea");
                    let maxlen = element.maxlen ? element.maxlen : false;
                    form_element = <>{label_element}<textarea
                        form="okd-inquiry-form"
                        name={element.name}
                        id={element.name}
                        placeholder={element.placeholder}
                        required={element.required}
                        maxLength={maxlen}
                    /></>;
                    
                    form_elements.push(form_element);
                    break;
                case "select":
                    console.log("gentest, in case textarea");
                    let options = []
                    let multiple  = element.multiple ? element.multiple : false;
                    console.log("gentest options", element, element.tag);
                    for (const option of element.options) {
                        //If the option has both text and a value, then allow it to render.;
                        if (option.value && option.text) {
                            let option_element;
                            let disabled_switch = false;
                            let selected_switch = false;
                            let label_content;
        
                            if (option.disabled) {
                                disabled_switch = option.disabled;
                            } else if (option.selected) {
                                selected_switch = option.selected;
                            }
        
                            if (option.label) {
                                label_content = option.label;
                            }
        
                            option_element = <>{label_element}<option
                                value={option.value}
                                disabled={disabled_switch}
                                selected={selected_switch}
                                label={label_content}
                            >{option.text}</option></>
        
                            options.push(option_element)
                        }
                    }
    
                    if (options.length !== 0) {
                        form_element = <select form="okd-inquiry-form" id={element.name} name={element.name} required={element.required} multiple={multiple}>
                            {options}
                        </select>
        
                        form_elements.push(form_element);
                    }
                    break;
                default:
                    break;
            }
        }
    }

    function inquiryFunction(e) {
        //stop form submission
        e.preventDefault();
        let values = {};
        [...e.target.children[0].children].map(item => {
            if (item.tagName !== "LABEL") {
                console.log("btc-242, element required?", item.required);
                console.log("Adding the following to values:", item.value, "| at: ", (item.name).replace("inquire-",""), "| tag:", item.tagName);
                values[(item.name).replace("inquire-","")] = item.value;
            }
        });

        console.log("btc-242 - Before Sending an email.", values)
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({...values, "template":"inquiry_basic_template"}),
        };

        console.log("btc-242 - Sending an email.", options)
        fetch(`${firestoreURlImport}/sendInquiry`, options).then((data) => {
            console.log("Result of the email:", data);
            props.setModalWindow("");
        });
    }

    return (
        <div className="okd-login-modal-background">
            <div className="okd-login-window">
                <div style={{textAlign:"right", height:"1em"}}>
                    <span className="okd-close-modal" onClick={() => {props.setModalWindow("")}}>X</span>
                </div>
                <div>
                    <h3 style={{margin:"unset"}}>Inquire</h3>
                    <form /*action={`${firestoreURlImport}/sendInquiry`}*/ name="okd-inquiry-form" id="okd-inquiry-form" action="" onSubmit={(e) => {inquiryFunction(e)}}>
                        <span>{form_elements}</span>
                        <input className="black-button" type="submit" value="Submit" />
                    </form>
                </div>
            </div>
        </div>
    )
}

export default InquireModal;