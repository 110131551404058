//Firebase stuff
import { initializeApp } from 'firebase/app';
import { getStorage, ref, listAll, getDownloadURL, uploadBytes } from 'firebase/storage';
import firebaseConfig from './global-config.js';

// Your web app's Firebase configuration

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);


const convertImageToBase64 = (url) => {
	return new Promise((resolve, reject) => {
		const img = new Image();
		img.crossOrigin = 'Anonymous'; // Handle CORS
		img.src = "";
		img.onload = () => {
			// Create a canvas
			const canvas = document.createElement('canvas');
			const ctx = canvas.getContext('2d');
			const maxWidth = 500;
			const maxHeight = 500;
	
			let newWidth, newHeight;
	
			if (img.width > img.height) {
			newWidth = maxWidth;
			newHeight = (img.height * maxWidth) / img.width;
			} else {
			newHeight = maxHeight;
			newWidth = (img.width * maxHeight) / img.height;
			}
	
			canvas.width = newWidth;
			canvas.height = newHeight;

			// Draw the image onto the canvas and resize it
			ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

			// Get the base64 encoded string
			const base64Image = canvas.toDataURL('image/png');
			resolve(base64Image);
		};
		img.onerror = reject;
		img.src = url;
	});
};

// GET THE DESIGN PREVIEWS WHEN THE incoming.designs CHANGES
const fetchLogos = async (designs, file="previews") => {
	let image_name = "";
	let user_file = "";
	let design_map = ('logos' in designs && file === "icons") ? Object.values(designs.logos) : Object.values(designs);
	//console.log("PREIV", designs, design_map);
  
	const designsWithPreviews = [];
  
	for (const design of design_map) {
		let url = "";
	
		try {
			if (file === "previews") {
				user_file = design.id;
				image_name = "preview";
				//console.log("PREIV: USER FILE design IS", user_file);
		
				/*console.log(
					"Searching",
					`gs://online-kit-designer.appspot.com/${file}/${user_file}/${image_name}.png`
				);*/
				let fileRef = ref(
					storage,
					`gs://online-kit-designer.appspot.com/${file}/${user_file}/${image_name}.png`
				);
				url = await getDownloadURL(fileRef);
			} else if (file === "icons") {
				//If there is no name then the logo slot is empty. Therefore, pass it over
				if (design.name !== "") {
					user_file = designs.user;
					console.log("getPreviewData fetchDesigns", file, designs);
					
					//If the design is a treatment, look for the parent
					if (design.treatment) {
						image_name = design.parent;
					} else {
						image_name = design.name;
					}
					
					let folderRef = ref(
						storage,
						`gs://online-kit-designer.appspot.com/${file}/${user_file}/`
					);

					console.log("getPreviewData fetchDesigns data_result address:",`gs://online-kit-designer.appspot.com/${file}/${user_file}/`)
					
					//console.log("MoVTru PREIV, folderRef:", `gs://online-kit-designer.appspot.com/${file}/${user_file}/`, image_name)
					const items = await listAll(folderRef);
					const matchingItem = items.items.find(
						(item) => item.name.toLowerCase().includes(image_name.toLowerCase())
					);
		
					if (matchingItem) {
						url = await getDownloadURL(matchingItem);
						//console.log('MovTru PREIV: Image URL:', image_name, url.substring(0,25));
					} else {
						//console.log('MovTru PREIV: No matching item found.', image_name.toLowerCase());
					}
				}
			}
	
			//console.log("PREIV TINNITIUS: should be second");
			const base64Image = await convertImageToBase64(url);
	

			if (designsWithPreviews.some(e => e.previewData === base64Image)) {
				/* vendors contains the element we're looking for */
				//console.log(image_name, "is already in there!");
			} else if (base64Image !== "") {
				designsWithPreviews.push({ ...design, previewData: base64Image });
				//console.log("MoVTru focus PREIV: Success with ", user_file, base64Image.substring(0, 25), designsWithPreviews);
			} else {
				designsWithPreviews.push(design);
				//console.log("MoVTru focus PREIV: Failure with ", user_file, designsWithPreviews);
			}
		} catch (error) {
			console.error('Error in processing design:', error);
			designsWithPreviews.push(design);
		}
	}
  
	return designsWithPreviews;
};
  



export { storage, getDownloadURL, fetchLogos };