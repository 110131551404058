import { useEffect, useState } from "react";
import { addDoc, updateDoc, setDoc, collection, doc } from "firebase/firestore";
import { fetchValue, fetchDoc, dbx, getDoc } from '../firebase-config.js';
import { ref } from "valtio";
import firestoreURlImport from '../firestore_url.js';


function LoginModal(props) {
	//const [imagesSaveable, setImagesSaveable] = useState(false);
	const [modalOpen, setModalOpen] = useState(props.openModal);
	
	let email_regex = /(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/ig;
	let email_JSX = <> <input type="text" name="okd-user-email-input" id="okd-user-email-input" placeholder="Email address"/><button className="black-button" onClick={(e) => {testFirestoreFunction(e, "email")}}>Submit</button> </>
	let code_JSX = <>
	<div id='okd-confirmation-code'>
		<input maxLength={1} data-index="1" onChange={(e) => {saveCodeOnChange(e)}} />-
		<input maxLength={1} data-index="2" onChange={(e) => {saveCodeOnChange(e)}} />-
		<input maxLength={1} data-index="3" onChange={(e) => {saveCodeOnChange(e)}} />-
		<input maxLength={1} data-index="4" onChange={(e) => {saveCodeOnChange(e)}} />-
		<input maxLength={1} data-index="5" onChange={(e) => {saveCodeOnChange(e)}} />-
		<input maxLength={1} data-index="6" onChange={(e) => {saveCodeOnChange(e)}} />
	</div><button className="black-button" onClick={(e) => {testFirestoreFunction(e, "passcode")}}>Submit</button>{/*<button className="black-button" onClick={(e) => {testFirestoreFunction(e, "close")}}>Cancel</button>*/}  </>
	
	const [emailInput, setEmailInput] = useState(email_JSX);
	
	let email_value;
	let error_element = document.getElementById('okd-login-error');

	useEffect(() => {
		error_element = document.getElementById('okd-login-error');
		console.log("New error element", error_element);
	}, [emailInput])

	// Function to test Firestore function
	const testFirestoreFunction = async (e, input) => {
		let response_json;
		if (input === "email") {
			if (document.getElementById('okd-user-email-input').value) {
				if (error_element) {
					error_element.innerHTML = '';
				}
				props.setUserEmail(document.getElementById('okd-user-email-input').value);
				email_value = document.getElementById('okd-user-email-input').value
				// Set up options for the fetch request
				console.log("login button: before sent", email_value, email_value.value);
				if (email_value && email_value.match(email_regex)) {
					const options = {
						method: 'POST',
						headers: {
						'Content-Type': 'application/json',
						},
						body: JSON.stringify({"email":email_value, "temp_access":props.tempCode, "passcode":"n/a", "intent":"save_logos"}),
					};
					console.log("login button: awaiting response", JSON.parse(options.body));
					const response = await fetch(`${firestoreUrl}/newVerifyLogIn`, options);
					response_json = await response.json();
				} else {
					if (error_element) {
						error_element.innerHTML = 'Please enter a valid email';
					}
				}

			} else {
				console.log("Enter something!");
				if (error_element) {
					error_element.innerHTML = 'Please enter an email';
				}
			}
		} else if (input === "passcode") {
			console.log(props.userEmail, email_value);
			let passcode_container = document.getElementById('okd-confirmation-code');
			let passcode = "";

			//For each of the input fields for the code
			for (let index = 0; index < passcode_container.children.length; index++) {
				const element = passcode_container.children[index];
				//If there's a value, then use it to build the user's passcode.
				if (element.value) {
					passcode += ("" + element.value)
				}
			}

			console.log("Passcode is wrong length?", passcode, passcode.length);
			if (!(passcode.length < 6)) {
				if (error_element) {
					error_element.innerHTML = '';
				}

				const options = {
					method: 'POST',
					headers: {
					'Content-Type': 'application/json',
					},
					body: JSON.stringify({"email":email_value, "temp_access":props.tempCode, "passcode":passcode, "intent":"save_logos"}),
				};
				
				console.log("login button: awaiting response", JSON.parse(options.body));
				const response = await fetch(`${firestoreUrl}/newVerifyLogIn`, options);
				response_json = await response.json();
			} else {
				console.log("Passcode is wrong length!", error_element);
				if (error_element) {
					error_element.innerHTML = 'Please enter the full passcode'	
				}
			}
		} else if (input === "close") {
			setModalOpen(false);
			//setEmailInput(email_JSX);	
		}

		console.log(response_json);

		if (response_json) {
			if ("action" in response_json) {
				if (response_json.action === "enter-code") {
					setModalOpen(true);
					setEmailInput(code_JSX);
				} else if (response_json.action === "email-window") {
					setModalOpen(true);
					setEmailInput(email_JSX);
				} else if (response_json.action === "proceed-to-save") {
					setModalOpen(false);
					document.getElementById('image_save_button').click();
				} else {
					console.log("Explanation:", response_json);
				}
			}
			
			if ("error" in response_json) {
				error_element.innerHTML = 'Incorrect passcode, please try again'
			} else if (error_element) {
				error_element.innerHTML = ''
			}
		}
	};

	function saveCodeOnChange(event) {
		if (event.target.value.length === 1) {
			let index_no = parseInt(event.target.dataset.index);
			if (index_no < 6) {
				let elements = document.querySelectorAll(`[data-index="${index_no+1}"]`)
				//console.log("Pressed.", event.target.dataset.index, elements, index_no+1);
				elements[0].focus();
			}
		}// else {
		//	console.log(event.target);
		//}
	}

	// Determine Firestore URL based on environment
	let firestoreUrl = firestoreURlImport;

	if (modalOpen === false) {
		console.log(modalOpen, "opening?");
		return (<></>)
	} else {
		console.log(modalOpen, "opening?");
		return (
			<div className="okd-login-modal-background">
				<div className="okd-login-window">
                	<div style={{textAlign:"right", height:"1em"}}>
						<span className="okd-close-modal" onClick={() => {props.setModalWindow("")}}>X</span>
					</div>
					<div /*id="okd-save-further-input"*/>
						{emailInput}
						<div id="okd-login-error" className="okd-error"></div>
					</div>
				</div>
			</div>
		)
	}

}

export default ( LoginModal )